<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          min-width="100px"
          class="my-5"
        />
        <v-divider />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
        <v-skeleton-loader
          type="article"
          min-width="100px"
          class="my-5"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12">
        <v-form>
          <div>
            <BankCover v-model="bank" />
            <BankFinancial v-model="bank" />
            <BankContactNumber v-model="bank" />
            <BankContactEmail v-model="bank" />
            <ExchangeStores
              v-model="bank"
              v-if="bank.exchangeType && bank.exchangeType.id && bank.exchangeType.id != 1"
            />
          </div>
        </v-form>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              @click="cancelBankCreation"
              class="btn-tertiary float-right"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn-primary mx-2 float-right"
              @click="!editMode ? save() : update()"
              text
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import BankCover from "@/components/form-exchange-bank/BankCover.vue";
import BankFinancial from "@/components/form-exchange-bank/BankFinancial.vue";
import BankContactNumber from "@/components/form-exchange-bank/BankContactNumber.vue";
import BankContactEmail from "@/components/form-exchange-bank/BankContactEmail.vue";
import ExchangeStores from "@/components/form-exchange-bank/ExchangeStores.vue";

// Apis
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";

// Models
import ExchangeBankModel from "@/model/exchange-bank-model";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "ExchangeFormBank",
  mixins: [mixinMessage, mixinGeral],
  components: {
    BankCover,
    BankFinancial,
    BankContactNumber,
    BankContactEmail,
    ExchangeStores,
  },
  data() {
    return {
      loading: false,
      editMode: false,
      bank: new ExchangeBankModel(),
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.loading = true;
      this.getBankById(this.$route.params.id);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
  methods: {
    getBankById(id) {
      apiExchangeBank
        .findId(id)
        .then((response) => {
          this.bank = new ExchangeBankModel(response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    save() {
      mixpanel.track("click", {
        button_name: "save_bank",
      });
      this.createBank();
    },
    update() {
      mixpanel.track("click", {
        button_name: "update_bank",
      });
      this.updateBank();
    },
    cancelBankCreation() {
      mixpanel.track("click", {
        button_name: "cancel_bank_creation",
      });
      this.$router.push({
        name: "ExchangeBankControl",
      });
    },
    createBank() {
      apiExchangeBank
        .add(this.bank)
        .then(() => {
          this.sendMessage("Banco salvo com sucesso!", "success");

          this.$router.push({
            name: "ExchangeBankControl",
          });
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    updateBank() {
      apiExchangeBank
        .update(this.bank)
        .then(() => {
          this.sendMessage("Banco salvo com sucesso!", "success");

          this.$router.push({
            name: "ExchangeBankControl",
          });
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
  },
};
</script>
