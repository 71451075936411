export default class SketchFilterModel {
  constructor(obj) {
    obj = obj || {};

    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.typeId = obj.typeId;
    this.agentId = obj.agentId;
    this.customerId = obj.customerId;
    this.bankId = obj.bankId;
    this.currencyId = obj.currencyId;
  }
}