<template>
  <v-list
    dense
    nav
  >
    <v-list-item-group class="pt-15">
      <v-list-item
        link
        :to="'/'"
      >
        <v-list-item-action>
          <i class="far fa-chart-pie" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessCustomers()">
        <v-list-item-action>
          <i class="far fa-users" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Clientes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessCustomerAuthorizations()">
        <v-list-item-action>
          <i class="far fa-clipboard-check" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Fluxo Cadastral</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessOperations()">
        <v-list-item-action>
          <i class="far fa-hand-holding-usd" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Operações</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="accessOpenOperation()">
        <v-list-item-action>
          <i class="far fa-file-edit" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Rascunhos (Operações Em Aberto)</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group no-action>
        <template #prependIcon>
          <i class="far fa-file-alt" />
        </template>
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>Relatórios Operacionais</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item @click="accessRemittanceOperationsReports()">
          <v-list-item-content>
            <v-list-item-title>Remessas Internacionais</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="accessRemittanceOperationsManagementReports()">
          <v-list-item-content>
            <v-list-item-title>Lucro / Remessas Internacionais</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="accessExchangeOperationsReports()">
          <v-list-item-content>
            <v-list-item-title>Câmbio Turismo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Lucro / Câmbio Turismo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group no-action>
        <template #prependIcon>
          <i class="far fa-briefcase" />
        </template>
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>Corban</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item @click="accessCorrespondents()">
          <v-list-item-content>
            <v-list-item-title>Cadastros</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="accessCommissionControl()">
          <v-list-item-content>
            <v-list-item-title>Comissões</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group no-action>
        <template #prependIcon>
          <i class="far fa-plus-circle" />
        </template>
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>Cadastros Operacionais</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item @click="accessOperationNatures()">
          <v-list-item-content>
            <v-list-item-title>Naturezas de Operação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="accessBanks()">
          <v-list-item-content>
            <v-list-item-title>Bancos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="accessCurrencies()">
          <v-list-item-content>
            <v-list-item-title>Spread / Moedas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="acessPaymentMethods()">
          <v-list-item-content>
            <v-list-item-title>Métodos de Pagamento</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="acessRateApis()">
          <v-list-item-content>
            <v-list-item-title>Apis de Cotação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="acessTemplates()">
          <v-list-item-content>
            <v-list-item-title>Templates</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item @click="accessUsers()">
        <v-list-item-action>
          <i class="far fa-user" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Usuários</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
    <v-list-item @click="accessAudit()">
      <v-list-item-action>
        <i class="far fa-user-shield" />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Auditoria</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import mixinAuthorization from "../../mixin/mixin-authorization";
import { ACCESS_ROUTES } from "@/constants/general-constants";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "MenuStatic",
  mixins: [mixinAuthorization],
  data() {
    return {
      acess: ACCESS_ROUTES,
    };
  },
  methods: {
    accessAudit() {
      mixpanel.track("click", {
        button_name: "menu_audit",
      });
      this.$router
        .push({
          name: "AuditControl",
        })
        .catch(() => { });
    },
    accessUsers() {
      mixpanel.track("click", {
        button_name: "menu_users",
      });
      this.$router
        .push({
          name: "UsersControl",
        })
        .catch(() => { });
    },
    accessCustomers() {
      mixpanel.track("click", {
        button_name: "menu_customers",
      });
      this.$router
        .push({
          name: "CustomersControl",
        })
        .catch(() => { });
    },
    accessBanks() {
      mixpanel.track("click", {
        button_name: "menu_banks",
      });
      this.$router
        .push({
          name: "ExchangeBankControl",
        })
        .catch(() => { });
    },

    accessCustomerAuthorizations() {
      mixpanel.track("click", {
        button_name: "menu_customer_authorizations",
      });
      this.$router
        .push({
          name: "CustomersAuthorizationControl",
        })
        .catch(() => { });
    },

    accessCorrespondents() {
      mixpanel.track("click", {
        button_name: "menu_correspondents",
      });
      this.$router
        .push({
          name: "CorrespondentsControl",
        })
        .catch(() => { });
    },

    accessOperationNatures() {
      mixpanel.track("click", {
        button_name: "menu_registrations_operation_nature",
      });
      this.$router
        .push({
          name: "OperationNaturesControl",
        })
        .catch(() => { });
    },

    accessCurrencies() {
      mixpanel.track("click", {
        button_name: "menu_registrations_currencies",
      });
      this.$router
        .push({
          name: "CurrenciesControl",
        })
        .catch(() => { });
    },

    acessPaymentMethods() {
      mixpanel.track("click", {
        button_name: "payment_methods",
      });
      this.$router
        .push({
          name: "PaymentMethodsControl",
        })
        .catch(() => { });
    },

    accessOperations() {
      mixpanel.track("click", {
        button_name: "menu_operations",
      });
      this.$router
        .push({
          name: "FormOperation",
        })
        .catch(() => { });
    },

    accessOpenOperation() {
      mixpanel.track("click", {
        button_name: "menu_open_operations",
      });
      this.$router
        .push({
          name: "OpenOperationsReport",
        })
        .catch(() => { });
    },

    accessExchangeOperationsReports() {
      mixpanel.track("click", {
        button_name: "menu_exchange_operations_reports",
      });
      this.$router
        .push({
          name: "ExchangeOperationReport",
        })
        .catch(() => { });
    },

    accessRemittanceOperationsReports() {
      mixpanel.track("click", {
        button_name: "menu_remittance_operations_reports",
      });
      this.$router
        .push({
          name: "RemittanceOperationReport",
        })
        .catch(() => { });
    },

    accessRemittanceOperationsManagementReports() {
      mixpanel.track("click", {
        button_name: "menu_remittance_management_operations_reports",
      });
      this.$router
        .push({
          name: "RemittanceOperationManagementReport",
        })
        .catch(() => { });
    },

    accessCommissionControl() {
      mixpanel.track("click", {
        button_name: "menu_commission_control",
      });
      this.$router
        .push({
          name: "CommissionsControl",
        })
        .catch(() => { });
    },
    acessRateApis() {
      mixpanel.track("click", {
        button_name: "menu_registrations_rate_apis",
      });
      this.$router
        .push({
          name: "RateApiRouteControl",
        })
        .catch(() => { });
    },
    acessTemplates() {
      mixpanel.track("click", {
        button_name: "menu_registrations_templates",
      });
      this.$router
        .push({
          name: "TemplatesControl",
        })
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-item--active.v-list-item--active.v-list-item.v-list-item--link,
.v-list .v-list-item--active .v-icon {
  color: var(--v-primary-base);
}
</style>
