import Vue from "vue";
import Vuex from 'vuex';

import configurations from "./module/configurations";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    configurations
  }
});