<template>
  <v-text-field
    v-model="mailData"
    :outlined="outlined"
    :label="label"
    autocomplete="off"
    type="email"
    dense
    :rules="[ ...rules, validationIsEmailValid ]"
  />
</template>

<script>

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "MailField",
  mixins: [ mixinValidationRules ],
  model: {
    prop: "model",
    event: "onChange",
  },
  data() {
    return {
      mailData: this.model,
      labelField: this.label,
    };
  },
  props: {
    model: {
      type: String,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    rules: { type: Array, required: false, default: () => [] },
    outlined: { type: Boolean, required: false, default: true },
  },
  watch: {
    mailData() {
      this.$emit("onChange", this.mailData);
    },
  },
};
</script>

<style>
</style>