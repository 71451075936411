<template>
  <div
    class="file-container"
    v-html="fileContent"
  />
</template>

<script>

// APIs
import api from "@/api/api";
import apiCustomersDocuments from "@/api/customer/customer-documents-api";
import apiCustomerOperationalDocuments from "@/api/customer/customer-operational-documents-api";
import apiOperationDocuments from "@/api/operation/operation-documents-api";

// Constants
import { FILE_TYPE } from "@/constants/general-constants";

export default {
  name: "FileView",
  props: {  },
  data() {
    return { 
      filePath: '',
      fileId: '',
      fileContent: '',
      file: undefined,
      fileType: '',
      downloadApi: undefined,
    };
  },

  mounted() {
    this.filePath = this.$route.params.pathMatch;
    this.fileId = this.$route.query.fileId;
    this.fileType = this.$route.query.fileType;

    if (this.fileId) {
      switch (this.fileType) {
        case FILE_TYPE.CUSTOMER_DOCUMENT:
          this.downloadApi = apiCustomersDocuments;
          break;
        case FILE_TYPE.CUSTOMER_OPERATIONAL_DOCUMENT:
          this.downloadApi = apiCustomerOperationalDocuments;
          break;
        case FILE_TYPE.OPERATION_DOCUMENT:
          this.downloadApi = apiOperationDocuments;
          break;
        default:
          this.downloadApi = apiCustomersDocuments;
          break;
      }

      this.downloadApi.download(`${this.fileId}`, { responseType: 'blob' })
        .then(response => {
          this.renderImage(response.data);
        })
        .catch(error => console.log(error));
    } else {
      api.get(`${this.filePath}`, { responseType: 'blob'})
        .then(response => {
          this.renderImage(response.data);
        })
        .catch(error => console.log(error));
    }
  },

  methods: { 
    renderImage(blob) {
      let ext = this.filePath.toString().split(".");
      ext = ext.reverse()[0].toString().toLowerCase();
      this.file = URL.createObjectURL(blob);

      let preview = null;
      switch (ext) {
        default:
        case "png":
        case "jpg":
        case "jpeg":
          preview = document.createElement("img");
          preview.src = this.file;
          break;
        case "svg":
          preview = document.createElement("object");
          preview.data = this.file;
          preview.type = "image/svg+xml";
          break;
        case "pdf":
          preview = document.createElement("embed");
          preview.src = this.file;
          preview.style.cssText =
            "position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;";
          preview.type = "application/pdf";
          break;
      }

      if (ext !== "pdf") {
        preview.style = `margin: 0 auto;`;
      }

      this.fileContent = preview.outerHTML;
    },
  },

  unmounted() {
    URL.revokeObjectURL(this.file);
  },
};
</script>

<style scoped>
.file-container  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 150px;
  background: #c6c6c6;
  display: flex;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

</style>