<template>
  <v-row
    :id="id"
    class="py-3"
    @drop.prevent="dragAndDrop($event)"
    @dragover.prevent
  >
    <div>
      <confirmation-window
        v-model="openConfirmationWindow"
        :callback-primary="callbackConfirmDelete"
        :message="deleteMessage"
      />
    </div>
    <v-col
      class="py-0"
      cols="12"
      md="6"
      v-if="!(description === false)"
    >
      <h2>
        {{ description || document.documentType.description }} <InfoTooltip
          v-if="info"
          icon-size="1x"
          :label="info"
        />
      </h2>
      <small
        v-if="document.required"
        class="required"
      >*Obrigatório</small>
    </v-col>
    <v-col
      v-if="showInput"
      class="py-0"
      cols="12"
      :md="description === false ? '12' : '6'"
    >
      <v-file-input
        label="Selecione ou arraste o arquivo"
        outlined
        dense
        prepend-icon="fa-paperclip"
        v-model="document.document"
        v-disabled-icon-focus
        :rules="document.required ? [validationIsRequiredFile] : []"
        :disabled="disabled"
      >
        <template #append-outer>
          <slot name="append-outer" />
        </template>
      </v-file-input>
    </v-col>
    <v-col
      v-else
      class="text-right py-0"
      :md="description === false ? '12' : '6'"
    >
      <v-chip
        class="mr-3 pa-2"
        color="green lighten-2"
        label
        small
        text-color="white"
      >
        Enviado em {{ document.createdAt || new Date() | dateHour }}
        <i class="far fa-check pl-2" />
      </v-chip>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            text
            icon
            @click="downloadDocument()"
            v-bind="attrs"
            v-on="on"
          >
            <i class="far fa-file-download icon" />
          </v-btn>
        </template>
        <span>Baixar</span>
      </v-tooltip>

      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn
            @click="viewDocument()"
            icon
            text
            v-bind="attrs"
            v-on="on"
          >
            <i class="far fa-eye icon" />
          </v-btn>
        </template>
        <span>Visualizar</span>
      </v-tooltip>

      <v-tooltip
        top
        v-if="!hideDelete"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            text
            @click="deleteDocument()"
            v-bind="attrs"
            v-on="on"
          >
            <i class="far fa-trash icon" />
          </v-btn>
        </template>
        <span>Excluir</span>
      </v-tooltip>

      <slot name="append-outer" />
    </v-col>
  </v-row>
</template>

<script>

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinValidationRules from "@/mixin/mixin-validation-rules";

// Components
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";
import InfoTooltip from "@/components/comum/InfoTooltip";

// Utils
import dateUtils from "../../../../common/utils/date";

export default {
  name: "DocumentInput",
  mixins: [ mixinMessage, mixinValidationRules ],
  components: { ConfirmationWindow, InfoTooltip },
  model: {
    prop: "input",
    event: "onChange",
  },
  props: {
    id: { type: String, required: false, default: undefined },
    input: { type: Object, required: true },
    api: { type: Object, required: true },
    description: { type: [String, Boolean], required: false, default: '' },
    customerId: { type: String, required: false, default: undefined },
    operationId: { type: String, required: false, default: undefined },
    info: { type: String, required: false, default: undefined },
    disabled: { type: Boolean, required: false, default: false },
    hideDelete: { type: Boolean, required: false, default: false },
    fileType: { type: String, required: false, default: undefined },
    documentPostSaveCallback: { type: Function, default: () => {} },
  },

  data() {
    return {
      document: { ...this.input },
      openConfirmationWindow: false,
      callbackConfirmDelete: () => {},
      deleteMessage: "",
    };
  },

  filters: {
    dateHour(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
  },

  watch: {
    "document.document"() {
      this.uploadDocument();
    },
  },

  methods: {
    dragAndDrop(e){
      if (!this.showInput || e.dataTransfer.files.length == 0) {
        return;
      }

      if (e.dataTransfer.files.length > 1) {
        this.sendMessage("É possível adicionar apenas um anexo por vez!", "error");
      }

      this.uploadDocument(e.dataTransfer.files[0]);
    },
    viewDocument() {
      let fileContainer = document.createElement("div");
      fileContainer.classList.add("file-container");

      if (this.document.id) {
        let routeData = this.$router.resolve({ 
            name: 'FileView', 
            params: {
              pathMatch: this.document.path.replace("uploads", ""),
            },
            query: {
              fileId: this.document.id,
              fileType: this.fileType,
            },
          });
        window.open(routeData.href, '_blank');

        return;
      }

      let file = window.URL.createObjectURL(this.document.document);
      let ext = this.document.document.name.toString().split(".");

      ext = ext.reverse()[0].toString().toLowerCase();

      let preview = null;
      switch (ext) {
        default:
        case "png":
        case "jpg":
        case "jpeg":
          preview = document.createElement("img");
          preview.src = file;
          break;
        case "svg":
          preview = document.createElement("object");
          preview.data = file;
          preview.type = "image/svg+xml";
          break;
        case "pdf":
          preview = document.createElement("embed");
          preview.src = file;
          preview.style.cssText =
            "position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;";
          preview.type = "application/pdf";
          break;
      }

      if (ext !== "pdf") preview.classList.add("zoom");

      let win = window.open("");
      win.document.write(`
        <style>
          body {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 150px;
            background: #c6c6c6;
          }
          .file-container {
            display: flex;
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
          .zoom {
            /* min-width: 400px;
            min-height: 400px; */
            margin: 0 auto;
            transition: transform 4s;
          }
          .zoom:hover {
            transform: scale(1.5);
          }
        </style>
        <body>
        </body>
      `);

      fileContainer.appendChild(preview);
      win.document.body.appendChild(fileContainer);

      win.document.onclose(() => {
        window.URL.revokeObjectURL(file);
      });
    },

    downloadDocument() {
      if (!this.document.id) {
        this.createDownloadLink(this.document.document);
        this.sendMessage("Baixando anexo", "success");

        return;
      }

      this.api
        .download(this.document.id)
        .then((response) => {
          this.createDownloadLink(response.data);

          this.sendMessage("Baixando anexo", "success");
        })
        .catch(() => {
          this.sendMessage("Falha ao baixar o anexo!", "error");
        });
    },

    createDownloadLink(file) {
      let link = document.createElement("a");

      link.href = URL.createObjectURL(file);
      link.setAttribute(
        "download",
        `${(this.document.documentType?.description || this.document.operationalDocumentType?.description || this.document.operationDocumentType?.description || '').replace(/\./g, "") }`
      );
      link.click();

      URL.revokeObjectURL(link.href);
    },

    deleteDocument() {
      this.deleteMessage = `Deseja realmente excluir este anexo? Esta ação não pode ser desfeita!`;
      this.callbackConfirmDelete = this.callbackDeleteDocument;
      this.openConfirmationWindow = true;
    },

    callbackDeleteDocument() {
      if (!this.document.id) {
        this.document = {
          documentType: this.document.documentType,
          operationalDocumentType: this.document.operationalDocumentType,
          operationDocumentType: this.document.operationDocumentType,
          required: this.document.required,
        };
        this.openConfirmationWindow = false;

        return;
      }

      this.api
        .remove(this.document.id)
        .then(() => {
          this.sendMessage("Anexo deletado com sucesso!", "success");
          this.document = {
            documentType: this.document.documentType,
            required: this.document.required,
            operationalDocumentType: this.document.operationalDocumentType,
            operationDocumentType: this.document.operationDocumentType,
          };
          this.$emit("onChange", this.document);
        })
        .catch(() => {
          this.sendMessage("Erro ao excluir o anexo!", "error");
        })
        .finally(() => {
          this.openConfirmationWindow = false;
        });
    },

    uploadDocument(document) {
      if ((!document && !this.document.document) || (!this.customerId && !this.operationId)) {
        this.$emit("onChange", this.document);

        return;
      }

      let formData = new FormData();

      formData.append("customerId", this.customerId);
      formData.append("operationId", this.operationId);
      formData.append("idDocumentType", this.document.documentType?.id);
      formData.append("idOperationalDocumentType", this.document.operationalDocumentType?.id);
      formData.append("idOperationDocumentType", this.document.operationDocumentType?.id);
      formData.append("document", document || this.document.document);

      this.api
        .add(formData)
        .then((response) => {
          this.document = {
            ...response.data,
            required: this.document.required,
          };
          this.documentPostSaveCallback(response);
          this.$emit("onChange", this.document);
        })
        .catch(() => {
          this.document = {
            documentType: this.document.documentType,
            operationalDocumentType: this.document.operationalDocumentType,
            operationDocumentType: this.document.operationDocumentType,
            required: this.document.required,
          };
          this.sendMessage("Erro ao adicionar o anexo!", "error");
        });
    },
  },

  computed: {
    showInput() {
      return !(
        (this.document.document || this.document.id) &&
        !(this.document.document && this.document.id)
      );
    },
  },
};
</script>
<style lang="scss">
.icon{
  font-size: 1.1rem!important;
}</style>