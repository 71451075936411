<template>
  <div class="box">
    <!-- Skeleton Loader -->
    <v-row v-if="isLoading">
      <v-col
        cols="12"
        lg="12"
      >
        <v-skeleton-loader
          class="mb-4"
          type="heading"
          max-width="500px"
        />
        <v-divider class="pt-2" />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-skeleton-loader
          type="list"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="120px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
        <v-skeleton-loader
          type="list-item-three-line"
          min-width="100px"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      class="pt-0"
    >
      <v-col
        cols="12"
        md="12"
      >
        <h1 class="titulo">Configurações</h1>
        <v-divider />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          class="card-cambio"
          height="100%"
        >
          <v-treeview
            :items="configurationsMenu"
            return-object
            item-key="title"
            open-on-click
            @update:active="nodeSelected"
            activatable
          >
            <template #prepend="{ item }">
              <i :class="'far fa-' + item.icon" />
            </template>
          </v-treeview>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-card class="card-cambio">
          <div
            v-if="!this.configurationMenu.id"
            class="config-wrapper"
          >
            <img
              src="@/assets/illustrations/configuration.svg"
              width="200px"
              alt=""
              srcset=""
            >
            <h1>Selecione um item para configurar</h1>
            <p>Navegue pelo menu lateral para realizar uma configuração no sistema</p>
          </div>
          <div
            v-else
            class="pa-5"
          >
            <div class="breadcrumb">
              <i class="far fa-home" />
              <span>{{ this.configurationMenu.title }}</span>
            </div>
            <v-divider class="my-3" />
            <PageConfiguration v-model="this.configurationMenu" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

// Constants
import { CONFIGURATIONS_MENU } from '@/constants/configurations-constants';



// Components
import PageConfiguration from './PageConfiguration.vue';

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import ConfigurationMenuModel from '@/model/generic/configuration-menu-model';

export default {
  name: 'Configuration',
  components: {
    PageConfiguration
  },
  mixins: [mixinMessage],
  data() {
    return {
      isLoading: true,
      configurationsMenu: CONFIGURATIONS_MENU,
      configurationMenu: new ConfigurationMenuModel(),
      editMode: true,
    };
  },
  methods: {
    nodeSelected(node) {
      this.configurationMenu = new ConfigurationMenuModel(node[0]);
    },
    save() {
      this.sendMessage('Configuração salva com sucesso!', 'success');
    },
  },
  mounted() {
    this.isLoading = false; // TODO: Ajustar para que após obter as configurações, atualizar o estado de carregamento.
  },
};

</script>
<style scoped lang="scss">
.config-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  text-align: center;
}

.breadcrumb {
  display: flex;
  gap: 20px;
  align-items: center;
}

.breadcrumb>span {
  font-size: 1rem;
}

.breadcrumb>i {
  color: var(--v-primary-base);
  padding: 0;
}

.actions-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>