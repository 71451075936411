import axios from 'axios';
import utilsStorage from '@/utils/storage';

const API_URL = process.env.VUE_APP_APP_URL;

const api = axios.create({
  baseURL: API_URL
});

api.interceptors.request.use(async (config) => {
  var token = await utilsStorage.getTokenOnStorage();
  config.headers.Authorization = token;

  return config;
});

api.interceptors.response.use((response) => {
  if (response.status === 401 && response.config.url !== "/login") {
    utilsStorage.removeAuth();

    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  }

  return response;
}, (error) => {

  if (!error.response) {
    return Promise.reject(`Não foi possível se conectar com o servidor. Se essa mensagem persistir, por favor, contate o suporte técnico.`);
  }

  if (error.response.status === 401 && error.response.config.url !== "/login") {
    utilsStorage.removeAuth();

    setTimeout(() => {
      window.location.href = '/login';
    }, 3000);

    return Promise.reject(`Seu login de acesso expirou, efetue o login novamente para acessar o sistema.`);
  }

  return Promise.reject(error);
});

export default api;