import api from '../api';

function add(method) {
  method = method || {};

  return new Promise((resolve, reject) => {
    return api.post('/payment-methods', method)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(method) {
  method = method || {};

  return new Promise((resolve, reject) => {
    return api.put(`/payment-methods/${method.id}`, method)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/payment-methods')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/payment-methods/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
};