'use strict';

/**
 * Cada uma dessas chaves deve ser única
 */
const PAGES_MENU_CONFIGURATION = {
  EMAIL: {
    TEMPLATE: {
      VALUE: 'TEMPLATE',
      REMESSA: {
        PESSOA_FISICA: {
          CLIENTE: 'EMAIL_TEMPLATE_REMESSA_CLIENTE_PF',
          BANCO: 'EMAIL_TEMPLATE_REMESSA_BANCO_PF',
          CORBAN: 'EMAIL_TEMPLATE_REMESSA_CORBAN_PF',
        },
        PESSOA_JURIDICA: {
          CLIENTE: 'EMAIL_TEMPLATE_REMESSA_CLIENTE_PJ',
          BANCO: 'EMAIL_TEMPLATE_REMESSA_BANCO_PJ',
          CORBAN: 'EMAIL_TEMPLATE_REMESSA_CORBAN_PJ',
        },
      },
    },
    SMTP: {
      VALUE: "EMAIL_SMTP",
    }
  }

};

/**
 * O title de cada item é usado como key para a treeview, então deve ser único
 * O name é exibido na tela como o nome da página
 */
const CONFIGURATIONS_MENU = [
  {
    id: 1,
    name: "E-mails",
    icon: "envelope",
    session: "EMAIL",
    group: undefined,
    title: "E-mails",
    children: [
      {
        id: "1",
        name: "Templates",
        session: "EMAIL",
        group: "TEMPLATE",
        title: "E-mails > Templates",
        children: [
          {
            id: "1",
            name: "Remessa",
            session: "EMAIL",
            group: "TEMPLATE",
            title: "E-mails > Templates > Remessa",
            children: [
              {
                id: "1",
                name: "Pessoa Física",
                session: "EMAIL",
                group: "TEMPLATE",
                title: "E-mails > Templates > Remessa > Pessoa Física",
                children: [
                  {
                    id: "1",
                    name: "Cliente",
                    session: "EMAIL",
                    group: "TEMPLATE",
                    title: "E-mails > Templates > Remessa > Pessoa Física > Cliente",
                    page: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_FISICA.CLIENTE
                  },
                  {
                    id: "2",
                    name: "Banco",
                    session: "EMAIL",
                    group: "TEMPLATE",
                    title: "E-mails > Templates > Remessa > Pessoa Física > Banco",
                    page: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_FISICA.BANCO
                  },
                  {
                    id: "3",
                    name: "Corban",
                    title: "E-mails > Templates > Remessa > Pessoa Física > Corban",
                    session: "EMAIL",
                    group: "TEMPLATE",
                    page: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_FISICA.CORBAN
                  },
                ]
              },
              {
                id: "2",
                name: "Pessoa Jurídica",
                title: "E-mails > Templates > Remessa > Pessoa Jurídica",
                session: "EMAIL",
                group: "TEMPLATE",
                children: [
                  {
                    id: "1",
                    name: "Cliente",
                    session: "EMAIL",
                    group: "TEMPLATE",
                    title: "E-mails > Templates > Remessa > Pessoa Jurídica > Cliente",
                    page: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_JURIDICA.CLIENTE
                  },
                  {
                    id: "2",
                    name: "Banco",
                    session: "EMAIL",
                    group: "TEMPLATE",
                    title: "E-mails > Templates > Remessa > Pessoa Jurídica > Banco",
                    page: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_JURIDICA.BANCO
                  },
                  {
                    id: "3",
                    name: "Corban",
                    session: "EMAIL",
                    group: "TEMPLATE",
                    title: "E-mails > Templates > Remessa > Pessoa Jurídica > Corban",
                    page: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_JURIDICA.CORBAN
                  },
                ]
              },
            ]
          },
          // {
          //   id: "2",
          //   name: "Câmbio Turismo",
          //   title: "E-mails > Templates > Câmbio Turismo",
          //   session: "EMAIL",
          //   group: "TEMPLATE",
          // },
        ]
      },
      {
        id: 2,
        name: "Configurações de SMTP",
        session: "EMAIL",
        group: "SMTP",
        title: "E-mails > Configurações de SMTP",
        page: PAGES_MENU_CONFIGURATION.EMAIL.SMTP.VALUE
      },
    ]
  },
  // {
  //   id: 2,
  //   name: "Integrações",
  //   icon: "fa fa-users",
  //   session: "INTEGRATION",
  //   group: undefined,
  //   title: "Integrações",
  // },
];

/**
 * As contantes no VALUE devem ser as mesmas armazenadas no banco de dados.
 * A constante na key indica em que página essas constantes devem ser renderizadas
 */
const CONFIGURATIONS_NAME_TEMPLATE_REMESSA = [
  {
    KEY: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_FISICA.CLIENTE,
    VALUE: [
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_ENVIO_FICHA_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_ATUALIZACAO_FICHA_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_PENDENCIAS_CADASTRAIS',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_SOLICITACAO_DI_DUE',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_FECHAMENTO_OPERACAO_VENDA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_FECHAMENTO_OPERACAO_COMPRA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_LIMITE_OPERACIONAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_CADASTRO_FINALIZADO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_CONTRATO_CAMBIO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_SWIFT',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_CADASTRO_CONTA_EXTERIOR',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_CANCELAMENTO_OPERACAO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_PENDENCIA_OPERACAO_VENDA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CLIENTE_PENDENCIA_OPERACAO_COMPRA',
    ],
  },
  {
    KEY: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_FISICA.BANCO,
    VALUE: [
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_BANCO_ENVIO_FICHA_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_BANCO_ATUALIZACAO_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_BANCO_ENTREGA_DI_DUE',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_BANCO_FECHAMENTO_OPERACAO_VENDA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_BANCO_FECHAMENTO_OPERACAO_COMPRA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_BANCO_APROVACAO_NATUREZA_OPERACAO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_BANCO_DOCUMENTOS_OPERACIONAIS',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_BANCO_CANCELAMENTO_OPERACAO',
    ],
  },
  {
    KEY: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_FISICA.CORBAN,
    VALUE: [
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CORBAN_ATUALIZACAO_FICHA_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CORBAN_PENDENCIAS_CADASTRAIS',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CORBAN_SOLICITACAO_DI_DUE',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CORBAN_LIMITE_OPERACIONAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CORBAN_CADASTRO_FINALIZADO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CORBAN_AGRADECIMENTO_INDICACAO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CORBAN_RELATORIO_INDICADOR',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_FISICA_CORBAN_RELATORIO_FECHAMENTO',
    ],
  },
  {
    KEY: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_JURIDICA.CLIENTE,
    VALUE: [
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_ENVIO_FICHA_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_ATUALIZACAO_FICHA_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_PENDENCIAS_CADASTRAIS',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_SOLICITACAO_DI_DUE',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_FECHAMENTO_OPERACAO_VENDA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_FECHAMENTO_OPERACAO_COMPRA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_LIMITE_OPERACIONAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_CADASTRO_FINALIZADO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_CONTRATO_CAMBIO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_SWIFT',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_CADASTRO_CONTA_EXTERIOR',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_CANCELAMENTO_OPERACAO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_PENDENCIA_OPERACAO_VENDA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CLIENTE_PENDENCIA_OPERACAO_COMPRA',
    ],
  },
  {
    KEY: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_JURIDICA.BANCO,
    VALUE: [
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_BANCO_ENVIO_FICHA_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_BANCO_ATUALIZACAO_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_BANCO_ENTREGA_DI_DUE',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_BANCO_FECHAMENTO_OPERACAO_VENDA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_BANCO_FECHAMENTO_OPERACAO_COMPRA',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_BANCO_APROVACAO_NATUREZA_OPERACAO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_BANCO_DOCUMENTOS_OPERACIONAIS',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_BANCO_CANCELAMENTO_OPERACAO',
    ],
  },
  {
    KEY: PAGES_MENU_CONFIGURATION.EMAIL.TEMPLATE.REMESSA.PESSOA_JURIDICA.CORBAN,
    VALUE: [
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CORBAN_ATUALIZACAO_FICHA_CADASTRAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CORBAN_PENDENCIAS_CADASTRAIS',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CORBAN_SOLICITACAO_DI_DUE',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CORBAN_LIMITE_OPERACIONAL',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CORBAN_CADASTRO_FINALIZADO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CORBAN_AGRADECIMENTO_INDICACAO',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CORBAN_RELATORIO_INDICADOR',
      'EMAILS_TEMPLATE_REMESSA_PESSOA_JURIDICA_CORBAN_RELATORIO_FECHAMENTO',
    ],
  },
];

const CONFIGURATIONS_NAME_TEMPLATE = [
  ...CONFIGURATIONS_NAME_TEMPLATE_REMESSA,
];

const CONFIGURATIONS_NAME_SMTP = [
  {
    KEY: PAGES_MENU_CONFIGURATION.EMAIL.SMTP.VALUE,
    VALUE: [],
  }
];

const CONFIGURATIONS_NAME = [
  ...CONFIGURATIONS_NAME_TEMPLATE,
  ...CONFIGURATIONS_NAME_SMTP,
];


export {
  CONFIGURATIONS_MENU,
  PAGES_MENU_CONFIGURATION,
  CONFIGURATIONS_NAME,
};
