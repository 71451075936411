<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1 class="titulo">Operações em Aberto</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            cols="12"
            lg="3"
            sm="6"
            offset-lg="9"
            offset-sm="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>
          <v-col
            v-if="!operations.length"
            cols="12"
            md="12"
          >
            <EmptyTable
              title="Nenhum registro encontrado"
              icon="far fa-hand-holding-usd"
              subtitle="Não há operações em aberto para o dia de hoje."
            />
          </v-col>
          <v-col
            v-else
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="operations"
                :search="search"
                sort-by="id"
                sort-asc
                :items-per-page="10"
                :custom-filter="caseInsensitiveAccentsInsensitive"
              >
                <template #[`item.customer`]="{ item }">
                  <span>{{ item.customer.isPF() ? item.customer.name : item.customer.companyName }} - {{
                    item.customer.cpfCnpj }}</span>
                </template>

                <template #[`item.exchangeType.description`]="{ item }">
                  <template v-if="item.exchangeType?.id == 1 && item.exchangeBuy">
                    <span>Remessa de Compra</span>
                  </template>
                  <template v-else-if="item.exchangeType?.id == 1">
                    <span>Remessa de Venda</span>
                  </template>
                  <template v-else-if="item.exchangeType?.id == 2 && item.exchangeBuy">
                    <span>Compra de M.E.</span>
                  </template>
                  <template v-else>
                    <span>Venda de M.E.</span>
                  </template>
                </template>

                <template #[`item.updatedAt`]="{ item }">
                  <span>{{ item.updatedAt | time }}</span>
                </template>

                <template #[`item.amount`]="{ item }">
                  <span>{{ item | formatCurrencyAmount }}</span>
                </template>

                <template #[`item.customerRate`]="{ item }">
                  <span>{{ item | formatCurrencyCustomerRate }}</span>
                </template>

                <template #[`item.totalValue`]="{ item }">
                  <span>{{ item | formatCurrencyTotalValue }}</span>
                </template>

                <template #[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="editOperationSketch(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-pencil" />
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="getBankMessage(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-copy" />
                      </v-btn>
                    </template>
                    <span>Copiar Simulação do Banco</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="getCustomerMessage(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-copy" />
                      </v-btn>
                    </template>
                    <span>Copiar Simulação do Cliente</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="openFinishOperationDialog(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-check" />
                      </v-btn>
                    </template>
                    <span>Concluir</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Close Operation Dialog -->
    <v-dialog
      persistent
      v-model="finishOperationDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Métodos de Pagamento</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-method"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span>COTAÇÃO</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span>{{ operation | formatCurrencyQuotation }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span>M.E.</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span>{{ operation.currency.name }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span>VALOR M.E.</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span>{{ operation | formatCurrencyAmount }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span>TAXA DO CLIENTE</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span>{{ operation | formatCurrencyCustomerRate }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span>VET</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span>{{ operation | formatVet }}</span>
                  </v-col>
                  <v-col
                    class="text-left"
                    cols="12"
                    md="6"
                  >
                    <span>TOTAL</span>
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="12"
                    md="6"
                  >
                    <span>{{ operation | formatCurrencyTotalValue }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <MoneyField
                      label="Taxa do Banco"
                      prefix="R$ "
                      v-model="operation.bankRate"
                      :precision="6"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="finishOperation"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="finishOperationDialog = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiOperation from "@/api/operation/operation-api";
import apiSketch from "@/api/general/sketch-api";
import apiTrigger from "@/api/generic/trigger-api";

// Models
import OperationModel from "@/model/operation-model";
import SketchFilterModel from "@/model/general/sketch-filters-model";
import TriggerModel from "@/model/generic/trigger-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";

// Utils
import dateUtils from "../../../../common/utils/date";
import numberUtils from "../../../../common/utils/number";
import operationMessagesTemplate from "@/utils/operationMessagesTemplate";

// Constants
import { TYPES } from "../../../../common/constants/generic/sketches";
import { OPERATION_TYPE } from "../../../../common/constants/generic/types";
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";
import { OPERATION_WORKFLOW } from "../../../../common/workflows/operationWorkflow";


// Components
import MoneyField from "@/components/comum/MoneyField.vue";
import EmptyTable from "@/components/comum/EmptyTable.vue";
export default {
  name: "OpenOperationsReport",
  mixins: [mixinMessage, mixinDataTableFilters],
  components: { MoneyField, EmptyTable },
  data() {
    return {
      search: "",
      operations: [],
      columns: [
        { text: "Tipo", value: "exchangeType.description", align: "left", sortable: true },
        { text: "Cliente", value: "customer", align: "left", sortable: true },
        { text: "M.E.", value: "currency.name", align: "left", sortable: true },
        { text: "Valor M.E.", value: "amount", align: "left", sortable: true },
        { text: "Taxa do Cliente", value: "customerRate", align: "left", sortable: true },
        { text: "Valor Total", value: "totalValue", align: "left", sortable: true },
        { text: "Hora", value: "updatedAt", align: "left", sortable: true },
        { text: "Banco da operação", value: "bank.fantasyName", align: "left", sortable: true },
        { text: "Natureza", value: "nature.exibitionName", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false, width: '200px' },
      ],
      finishOperationDialog: false,
      operation: new OperationModel(),
    };
  },
  watch: {},
  mounted() {
    this.getOpenOperation();
  },
  computed: {},
  filters: {
    time(value) {
      return dateUtils.maskHourWithoutSeconds(value);
    },
    formatCurrencyAmount(operation) {
      let code = operation.currency.code || 'USD';

      return numberUtils.formatCurrency(code, operation.amount);
    },
    formatCurrencyTotalValue(operation) {
      return numberUtils.formatCurrency('BRL', operation.totalValue);
    },
    formatCurrencyCustomerRate(operation) {
      return numberUtils.formatCurrency('BRL', operation.customerRate, 6);
    },
    formatVet(operation) {
      return numberUtils.formatCurrency('BRL', operation.vet, 6);
    },
    formatCurrencyQuotation(operation) {
      return numberUtils.formatCurrency('BRL', operation.currencyQuotation, 6);
    },
  },
  methods: {
    getOpenOperation() {
      let filters = new SketchFilterModel({
        typeId: TYPES.OPERATION.id,
        startDate: new Date(),
        endDate: new Date()
      });
      apiSketch
        .find(filters)
        .then((response) => {
          this.operations = response.data.map((sketch) => {
            let operation = new OperationModel(JSON.parse(sketch.content));
            operation.sketchId = sketch.id;
            operation.updatedAt = sketch.updatedAt;

            return operation;
          });
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage(
              (error.response && error.response.data.mensagem) || error,
              "error"
            );
          }

          this.operations = [];
        });
    },

    getBankMessage(operation) {
      navigator.clipboard.writeText(operationMessagesTemplate.getBankMessage(operation));
    },

    getCustomerMessage(operation) {
      navigator.clipboard.writeText(operationMessagesTemplate.getCustomerMessage(operation));
    },

    openFinishOperationDialog(item) {
      this.operation = item;
      this.finishOperationDialog = true;
    },

    finishOperation() {
      this.operation.status = OPERATION_WORKFLOW.EM_ANALISE;
      this.operation.effectiveOperationDate = new Date().toISOString();

      apiOperation
        .add(this.operation)
        .then((response) => {
          let documentIds = this.operation.documents.map(d => d.id);
          let customerOperationalDocumentIds = this.operation.customerOperationalDocuments.map(d => d.id);

          if (this.operation.exchangeType.id == OPERATION_TYPE.REMMITANCE.id) {
            this.operationFinishedTrigger(response.data, documentIds, customerOperationalDocumentIds);
            this.operationIndicationAcknowledgementTrigger(response.data);
          }

          let index = this.operations.findIndex(o => o.sketchId == this.operation.sketchId);
          apiSketch.remove(this.operation.sketchId);
          this.operations.splice(index, 1);
          this.operation = new OperationModel();

          this.finishOperationDialog = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    editOperationSketch(operation) {
      this.$router
        .push({
          name: "FormEditOperation",
          params: {
            id: operation.sketchId,
          },
        })
        .catch(() => { });
    },
    operationFinishedTrigger(operation, documentIds, customerOperationalDocumentIds) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_FECHAMENTO,
        idOperation: operation.id,
        idCustomer: operation.customer.id,
        documentIds: documentIds,
        customerOperationalDocumentIds: customerOperationalDocumentIds,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => { })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    operationIndicationAcknowledgementTrigger(operation) {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.OPERACAO_AGRADECIMENTO_INDICACAO,
        idOperation: operation.id,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => { })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped>
#add-method {
  color: black;
}
</style>
