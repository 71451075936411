import api from '../api';

function add(correspondent) {
  correspondent = correspondent || {};

  return new Promise((resolve, reject) => {
    return api.post('/correspondents', correspondent)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(correspondent) {
  correspondent = correspondent || {};

  return new Promise((resolve, reject) => {
    return api.put(`/correspondents/${correspondent.id}`, correspondent)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/correspondents')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/correspondents/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function addIndicated(indicatorId, correspondent) {
  correspondent = correspondent || {};

  return new Promise((resolve, reject) => {
    return api.post(`/correspondents/${indicatorId}/indicated`, correspondent)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findIndicated(indicatorId) {
  return new Promise((resolve, reject) => {
    return api.get(`/correspondents/${indicatorId}/indicated`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findIndicators() {
  return new Promise((resolve, reject) => {
    return api.get(`/correspondents/indicators`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
  addIndicated,
  findIndicated,
  findIndicators,
};