import dateUtils from '../../../common/utils/date';
import numberUtils from '../../../common/utils/number';
import bankCache from "../../../common/cache/bank-cache";
const listOfBanks = await bankCache.getBankList();


function getCustomerMessage(operation) {
  if (operation.exchangeType.id == 1) {
    return _getCustomerMessageRemittance(operation);
  } else {
    return _getCustomerMessageExchange(operation);
  }
}

function _getCustomerMessageRemittance(operation) {
  if (operation.exchangeBuy) {
    return _getCustomerMessageRemittanceBuy(operation);
  } else {
    return _getCustomerMessageRemittanceSell(operation);
  }
}

function _getCustomerMessageRemittanceBuy(operation) {
  return `
*Simulação de operação - ME*
${dateUtils.maskDateAndHourIso(new Date())}

Tipo Operação -  Compra / Entrada
Moeda         -  ${operation.currency.name}
Nat. Operação -  ${operation.nature.exibitionName}

VALOR ME      -  ${numberUtils.formatCurrency(operation.currency.code, operation.amount)}
Tx. de Câmbio -  ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}
IOF (${operation.iof}%)   -  ${numberUtils.formatCurrency('BRL', operation.iofValue)}
I.R (${operation.ir}%)   -  ${numberUtils.formatCurrency('BRL', operation.irValue)}
Tx. Remessa   -  ${numberUtils.formatCurrency('BRL', operation.exchangeContractCostBrl)}
V.E.T.        -  ${numberUtils.formatCurrency('BRL', operation.vet, 4)}

TOTAL                     ${numberUtils.formatCurrency('BRL', operation.totalValue)}

*Cotação válida nos próximos 5 minutos*
*Sujeito a variação de acordo com o mercado*

_Seguem os dados bancário para realização do Pagamento_

A TED deve sair obrigatoriamente da conta do titular da operação. 

Banco..: ${operation.account.bank + ' - ' + listOfBanks.find(b => b.code == operation.account.bank).name}
Agência: ${operation.account.agency}
Conta..: ${operation.account.account} - ${operation.account.digit}
CNPJ: ${operation.bank.cnpj}
Favorecido: ${operation.bank.companyName}

*Favor nos enviar o comprovante assim que realizada.*

*AGUARDO SUAS INSTRUÇÕES PARA O FECHAMENTO JUNTO AO BANCO* 
`;
}

function _getCustomerMessageRemittanceSell(operation) {
  return `
*Simulação de operação - ME*
${dateUtils.maskDateAndHourIso(new Date())}

Tipo Operação -  Venda / Saída
Moeda         -  ${operation.currency.name}
Nat. Operação -  ${operation.nature.exibitionName}

VALOR ME      -  ${numberUtils.formatCurrency(operation.currency.code, operation.amount)}
Tx. de Câmbio -  ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}
IOF (${operation.iof}%)   -  ${numberUtils.formatCurrency('BRL', operation.iofValue)}
I.R (${operation.ir}%)   -  ${numberUtils.formatCurrency('BRL', operation.irValue)}
Tx. Remessa   -  ${numberUtils.formatCurrency('BRL', operation.exchangeContractCostBrl)}
V.E.T.        -  ${numberUtils.formatCurrency('BRL', operation.vet, 4)}

TOTAL                     ${numberUtils.formatCurrency('BRL', operation.totalValue)}

*Cotação válida nos próximos 5 minutos*
*Sujeito a variação de acordo com o mercado*

_Seguem os dados bancário para realização do Pagamento_

A TED deve sair obrigatoriamente da conta do titular da operação. 

Banco..: ${operation.paymentAccount.bank + ' - ' + listOfBanks.find(b => b.code == operation.paymentAccount.bank).name}
Agência: ${operation.paymentAccount.agency}
Conta..: ${operation.paymentAccount.account} ${operation.paymentAccount.digit ? '- ' + operation.paymentAccount.digit : ''}
CNPJ: ${operation.bank.cnpj}
Favorecido: ${operation.bank.companyName}

*Favor nos enviar o comprovante assim que realizada.*

*AGUARDO SUAS INSTRUÇÕES PARA O FECHAMENTO JUNTO AO BANCO* 
`;
}

function _getCustomerMessageExchange(operation) {
  if (operation.exchangeBuy) {
    return _getCustomerMessageExchangeBuy(operation);
  } else {
    return _getCustomerMessageExchangeSell(operation);
  }
}

function _getCustomerMessageExchangeBuy(operation) {
  return `
Simulação de Operação - ${dateUtils.maskDateAndHourIso(new Date())}

🏦Loja para Entrega - *${operation.store.name + ' - ' + operation.storeCity}*
📅Data para Entrega  - *${dateUtils.maskDateIso(operation.deliveryDate)}*

Tipo - *Compra*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, 4)}

Total a Receber - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

Forma de Pagto - *${operation.paymentMethod.name}*

Confirme seus Dados Bancários:👇

Banco -  *${operation.account.bank + ' - ' + listOfBanks.find(b => b.code == operation.account.bank).name}*
Agência - *${operation.account.agency}*
Conta - *${operation.account.account} - ${operation.account.digit}*
PIX - *${operation.account.pix}*

*Cotação válida nos próximos 5 minutos.**
*Sujeito a variação de acordo com o mercado***
*Aguarde confirmação de disponibilidade da moeda.****
`;
}

function _getCustomerMessageExchangeSell(operation) {
  if (operation.deliveryType.id == 1) {
    return _getCustomerMessageExchangeSellWithdrawal(operation);
  } else {
    return _getCustomerMessageExchangeSellDelivery(operation);
  }
}

function _getCustomerMessageExchangeSellWithdrawal(operation) {
  return `
Simulação de Operação - ${dateUtils.maskDateAndHourIso(new Date())}

🏦Loja de Retirada - *${operation.store.name + ' - ' + operation.storeCity}*
📅Data de Retirada  - *${dateUtils.maskDateIso(operation.deliveryDate)}*

Tipo - *Venda*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, 4)}

Total a Receber - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

Forma de Pagto - *${operation.paymentMethod.name}*

*🛑Seguem os dados bancário para realização do Pagamento.*
*⚠️A TED deve sair obrigatoriamente da conta do titular da operação.*

Banco -   ${operation.account.bank + ' - ' + listOfBanks.find(b => b.code == operation.account.bank).name}
Favorecido - ${operation.bank.companyName}
Agência - ${operation.account.agency}
Conta - ${operation.account.account} - ${operation.account.digit}
CNPJ - ${operation.bank.cnpj}
PIX - ${operation.account.pix}

*Cotação válida nos próximos 5 minutos.**
*Sujeito a variação de acordo com o mercado***
*Aguarde confirmação de disponibilidade da moeda.****
`;
}

function _getCustomerMessageExchangeSellDelivery(operation) {
  return `
Simulação de Operação - ${dateUtils.maskDateAndHourIso(new Date())}

🏦Local da Entrega - ${operation.street + ', ' + operation.number + ' ' + operation.city}
📅Data da Entrega  - *${dateUtils.maskDateIso(operation.deliveryDate)}*

Tipo - *Venda*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
Custo da Entrega - ${numberUtils.formatCurrency('BRL', operation.deliveryCost)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, 4)}

Total a Receber - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

Forma de Pagto - *${operation.paymentMethod.name}*

🛑Seguem os dados bancário para realização do Pagamento.
⚠️A TED deve sair obrigatoriamente da conta do titular da operação.

Banco -   ${operation.account.bank + ' - ' + listOfBanks.find(b => b.code == operation.account.bank).name}
Favorecido - ${operation.bank.companyName}
Agência - ${operation.account.agency}
Conta - ${operation.account.account} - ${operation.account.digit}
CNPJ - ${operation.bank.cnpj}
PIX - ${operation.account.pix}

*Cotação válida nos próximos 5 minutos.**
*Sujeito a variação de acordo com o mercado***
*Aguarde confirmação de disponibilidade da moeda.****
`;
}

function getBankMessage(operation) {
  if (operation.exchangeType.id == 1) {
    return _getBankMessageRemittance(operation);
  } else {
    return _getBankMessageExchange(operation);
  }
}

function _getBankMessageRemittance(operation) {
  if (operation.exchangeBuy) {
    return _getBankMessageRemittanceBuy(operation);
  } else {
    return _getBankMessageRemittanceSell(operation);
  }
}

function _getBankMessageRemittanceBuy(operation) {
  let customerName = operation.customer.isPF() ? operation.customer.name : operation.customer.companyName;

  return `
*INFORMAR COTAÇÃO*                               ${dateUtils.maskDateAndHourIso(operation.updatedAt) || dateUtils.maskDateAndHourIso(new Date())}

CLIENTE      - ${customerName}
CPF/CNPJ     - ${operation.customer.cpfCnpj}

TIPO DA OP   - Compra / Entrada
MOEDA        - ${operation.currency.code} - ${operation.currency.name}
VALOR ME     - ${numberUtils.formatCurrency(operation.currency.code, operation.amount)}
DESPESA      - ${numberUtils.formatCurrency('BRL', operation.exchangeContractCostBrl)}
I.R (${operation.ir}%)  - ${numberUtils.formatCurrency('BRL', operation.irValue)}
NATUREZA     - ${operation.nature.exibitionName}
LIQUIDAÇÃO   - ${operation.liquidation.description}
TAXA CLIENTE - ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}

*TOTAL:  ${numberUtils.formatCurrency('BRL', operation.totalValue)}* 

CANAL BANCÁRIO
NOME DO RECEBEDOR: ${customerName}
Banco..: ${operation.account.bank + ' - ' + listOfBanks.find(b => b.code == operation.account.bank).name}
Agência: ${operation.account.agency}
Conta..: ${operation.account.account} - ${operation.account.digit}
`;
}

function _getBankMessageRemittanceSell(operation) {
  let customerName = operation.customer.isPF() ? operation.customer.name : operation.customer.companyName;

  return `
*INFORMAR COTAÇÃO*                               ${dateUtils.maskDateAndHourIso(operation.updatedAt) || dateUtils.maskDateAndHourIso(new Date())}

CLIENTE      - ${customerName}
CPF/CNPJ     - ${operation.customer.cpfCnpj}

TIPO DA OP   - ${operation.exchangeBuy ? 'Compra / Entrada' : 'Venda / Saída'}
MOEDA        - ${operation.currency.code} - ${operation.currency.name}
VALOR ME     - ${numberUtils.formatCurrency(operation.currency.code, operation.amount)}
DESPESA      - ${numberUtils.formatCurrency('BRL', operation.exchangeContractCostBrl)}
I.R (${operation.ir}%)  - ${numberUtils.formatCurrency('BRL', operation.irValue)}
NATUREZA     - ${operation.nature.exibitionName}
LIQUIDAÇÃO   - ${operation.liquidation.description}
TAXA CLIENTE - ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}

*TOTAL:  ${numberUtils.formatCurrency('BRL', operation.totalValue)}* 

CANAL BANCÁRIO
NOME DO RECEBEDOR - ${customerName}
BANCO - ${operation.account.bank}
SWIFT - ${operation.account.swiftCodeBIC}
CONTA - ${operation.account.numberIBAN}
ROUTING/ABA - ${operation.account.tabNumberRouting}
`;
}

function _getBankMessageExchange(operation) {
  if (operation.exchangeBuy) {
    return _getBankMessageExchangeBuy(operation);
  } else {
    return _getBankMessageExchangeSell(operation);
  }
}

function _getBankMessageExchangeBuy(operation) {
  return `
Consulta de Disponibilidade - ${dateUtils.maskDateAndHourIso(operation.updatedAt) || dateUtils.maskDateAndHourIso(new Date())}

🏦Loja para Entrega - *${operation.store.name + ' - ' + operation.storeCity}*
📅Data para Entrega  - *${dateUtils.maskDateIso(operation.deliveryDate)}*

Tipo - *Compra*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, 4)}

Total a Receber - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

Forma de Pagto - *${operation.paymentMethod.name}*

Dados Bancários à pagar:👇

Banco -  ${operation.account.bank + ' - ' + listOfBanks.find(b => b.code == operation.account.bank).name}
Agência - ${operation.account.agency}
Conta - ${operation.account.account} - ${operation.account.digit}
PIX - ${operation.account.pix}

*⚠️Aguardo confirmação.**
`;
}

function _getBankMessageExchangeSell(operation) {
  if (operation.deliveryType.id == 1) {
    return _getBankMessageExchangeSellWithdrawal(operation);
  } else {
    return _getBankMessageExchangeSellDelivery(operation);
  }
}

function _getBankMessageExchangeSellWithdrawal(operation) {
  return `
Consulta de Disponibilidade - ${dateUtils.maskDateAndHourIso(operation.updatedAt) || dateUtils.maskDateAndHourIso(new Date())}

🏦Loja de Retirada - *${operation.store.name + ' - ' + operation.storeCity}*
📅Data de Retirada  - *${dateUtils.maskDateIso(operation.deliveryDate)}*

Tipo - *Venda*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, 4)}

Total a Receber - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

Forma de Pagto - *${operation.paymentMethod.name}*

*⚠️Aguardo confirmação.**
`;
}

function _getBankMessageExchangeSellDelivery(operation) {
  return `
Consulta de Disponibilidade - ${dateUtils.maskDateAndHourIso(operation.updatedAt) || dateUtils.maskDateAndHourIso(new Date())}

🏦Local da Entrega - ${operation.street + ', ' + operation.number + ' ' + operation.city}
📅Data da Entrega  - *${dateUtils.maskDateIso(operation.deliveryDate)}*

Tipo - *Venda*
Moeda - *${operation.currency.code + ' - ' + operation.currency.name}*
Nat. Operação - ${operation.nature.exibitionName}

Valor ME - *${numberUtils.formatCurrency(operation.currency.code, operation.amount)}*
Tx. de Câmbio - ${numberUtils.formatCurrency('BRL', operation.customerRate, 6)}
IOF (${operation.iof}%) - ${numberUtils.formatCurrency('BRL', operation.iofValue)}
Custo da Entrega - ${numberUtils.formatCurrency('BRL', operation.deliveryCost)}
V.E.T.  ${numberUtils.formatCurrency('BRL', operation.vet, 4)}

Total a Receber - *${numberUtils.formatCurrency('BRL', operation.totalValue)}*

Forma de Pagto - *${operation.paymentMethod.name}*

*⚠️Aguardo confirmação.**
`;
}

function getBankInformation(operation) {
  if (operation.exchangeBuy) {
    return _getBrazilianBank(operation);
  } else {
    return _getIntenationalBank(operation);
  }
}

function _getBrazilianBank(operation) {
  return `
🏦 *Dados da conta do Brasil*
Banco: ${operation.account.name || ''}
Agência: ${operation.account.agency || ''}
Conta: ${operation.account.account || ''}
Digito: ${operation.account.digit || ''}
`;
}

function _getIntenationalBank(operation) {
  if (operation.account.intermediary) {
    return _getIntenationalBankWithIntermediary(operation);
  } else {
    return _getIntenationalBankWithoutIntermediary(operation);
  }
}

function _getIntenationalBankWithoutIntermediary(operation) {
  return `
🏦 *Dados da conta no exterior*
Banco: ${operation.account.bank || ''}
IBAN: ${operation.account.numberIBAN || ''}
Código Swift/BIC: ${operation.account.swiftCodeBIC || ''}
Número ABA/Routing: ${operation.account.tabNumberRouting || ''}
Titular: ${operation.account.ownerName || ''}
`;
}

function _getIntenationalBankWithIntermediary(operation) {
  return `
🏦 *Dados da conta no exterior*
Banco: ${operation.account.bank || ''}
IBAN: ${operation.account.numberIBAN || ''}
Código Swift/BIC: ${operation.account.swiftCodeBIC || ''}
Número ABA/Routing: ${operation.account.tabNumberRouting || ''}
Titular: ${operation.account.ownerName || ''}

🏦 *Dados do banco intermediário*
Banco: ${operation.account.intermediaryBankName || ''}
IBAN: ${operation.account.intermediaryCountry || ''}
Código Swift/BIC: ${operation.account.intermediaryAbaSwiftCode || ''}
`;
}


export default {
  getCustomerMessage,
  getBankMessage,
  getBankInformation
};