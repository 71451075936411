<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="button"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
      >
        <h1 class="titulo">E-mails Enviados</h1>
        <v-divider />
      </v-col>

      <v-col
        cols="12"
        md="3"
        sm="4"
        offset-md="9"
        offset-sm="8"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-card class="card-cambio">
          <v-data-table
            :headers="columns"
            :items="logs"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="10"
          >
            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt | date }}
            </template>

            <template #[`item.customer.name`]="{ item }">
              <span v-if="item.customer.isPF()">
                {{ item.customer.name }}
              </span>
              <span v-else>
                {{ item.customer.companyName }}
              </span>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    text
                    color="primary"
                    @click="resendEmail(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="far fa-inbox-out" />
                  </v-btn>
                </template>
                <span>Reenviar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

// Apis
import apiEmailLog from "@/api/logger/email-log-api";

// Models
import EmailLogModel from "@/model/logger/email-log-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Utils
import utilsStorage from "@/utils/storage";

// Tracking
import mixpanel from "mixpanel-browser";

// Utils
import dateUtils from "../../../../common/utils/date";

export default {
  name: "EmailLogsControl",
  mixins: [mixinMessage],
  data() {
    return {
      loading: true,
      search: "",
      user: utilsStorage.getUserOnStorage(),
      logs: [],
      columns: [
        { text: "Data de cadastro", value: "createdAt", align: "left", sortable: true },
        { text: "Assunto", value: "subject", align: "left", sortable: true },
        { text: "Cliente", value: "customer.name", align: "left", sortable: true },
        { text: "Corban", value: "corban.name", align: "left", sortable: true },
        { text: "Banco", value: "bank.companyName", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },
  mounted() {
    this.getLogList();
    mixpanel.track("page_view", {
      name_of_page_viewed: "email_logs_control",
      user_id: this.user.id,
    });
  },

  filters: {
    date(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
  },

  methods: {
    getLogList() {
      apiEmailLog
        .findAll()
        .then((response) => {
          this.logs = response.data.map((b) => new EmailLogModel(b));
        })
        .catch(() => {
          this.sendMessage(
            "Não foi possível obter os logs de e-mails enviados. Tente novamente mais tarde!",
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resendEmail(email) {
      apiEmailLog
        .resendEmail(email.id)
        .then(() => {
          this.sendMessage('E-mail reenviado!', 'success');
        })
        .catch(() => {
          this.sendMessage(
            "Não foi possível reenviar o e-mail. Tente novamente mais tarde!",
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
