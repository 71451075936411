import api from '../api';

function find(filters) {
  return new Promise((resolve, reject) => {
    return api.get('/customers/exchange-banks', { params: filters })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function updateStatus(idCustomer, idExchangeBank, statusUpdateDto) {
  idCustomer = idCustomer || {};
  idExchangeBank = idExchangeBank || {};
  statusUpdateDto = statusUpdateDto || {};

  return new Promise((resolve, reject) => {
    return api.patch(`/customers/${idCustomer}/bank/${idExchangeBank}/update-status`, statusUpdateDto)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  find,
  updateStatus,
};