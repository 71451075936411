<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          width="400px"
          class="py-4"
        />
        <v-divider />
        <v-skeleton-loader
          type="button"
          min-width="100px"
          class="py-5"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
      >
        <h1 class="titulo py-3">Auditoria</h1>
        <v-divider />
      </v-col>

      <v-col
        cols="12"
        lg="3"
        sm="6"
        offset-lg="9"
        offset-sm="6"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-card class="card-cambio">
          <v-data-table
            :headers="columns"
            :items="audits"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="10"
            item-key="id"
          >
            <template #[`item.entityDescription`]="{ item }">
              <v-chip
                small
                label
              >
                {{ item.entityDescription }}
              </v-chip>
            </template>
            <template #[`item.user`]="{ item }">
              {{ item.user.email ? item.user.email : 'Acesso externo' }}
            </template>
            <template #[`item.mutation`]="{ item }">
              <v-chip
                small
                label
              >
                {{ item.mutation }}
              </v-chip>
            </template>
            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt | dateAndHour }}
            </template>
            <template #[`item.entityId`]="{ item }">
              {{ item.entityDescription }} nº {{ item.entityId }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    icon
                    text
                    @click="previewAudit(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="far fa-eye" />
                  </v-btn>
                </template>
                <span>Visualizar registro</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      max-width="1000px"
      v-model="modalPreviewAudit"
    >
      <v-card>
        <v-card-title>
          <h3>Visualizar registro</h3>
        </v-card-title>
        <v-divider class="mt-3 pb-3" />
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <h2>{{ titleBeforeAudit }}</h2>
                  <pre>{{ contentBeforeAudit }}</pre>
                  <small>Última atualização em {{ this.audit.createdAt | dateAndHour }}</small>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <h2>{{ titleAfterAudit }}</h2>
                  <pre>{{ contentAfterAudit }}</pre>
                  <small>Última atualização em {{ this.audit.createdAt | dateAndHour }}</small>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-tertiary ma-2"
            @click="closeModalPreview"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Apis
import apiAudit from "@/api/generic/audit-api";
import apiUser from "@/api/user/user-api";

// Models
import AuditModel from "@/model/generic/audit-model";
import UserModel from "@/model/user-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinAuthorization from "@/mixin/mixin-authorization";

// Utils
import dateUtils from "../../../../common/utils/date";

// Constants
import { ENTITY, MUTATIONS } from "../../../../common/constants/generic/types";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "AuditControl",
  mixins: [mixinMessage, mixinAuthorization],
  components: {
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    dateAndHour(value) {
      return dateUtils.maskDateAndHourWithoutSeconds(value);
    },
  },
  data() {
    return {
      loading: true,
      search: "",
      columns: [
        { text: "Entidade", value: "entityDescription", align: "center", sortable: true, },
        { text: "Ação", value: "mutation", align: "left", sortable: true },
        { text: "Usuário vinculado", value: "user", align: "left", sortable: true },
        { text: "Entidade vincluada", value: "entityId", align: "left", sortable: true },
        { text: "Data de cadastro", value: "createdAt", align: "center", sortable: true, },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      audits: [],
      modalPreviewAudit: false,
      listOfEntities: ENTITY,
      listOfMutations: MUTATIONS,
      users: [],
      audit: new AuditModel(),
    };
  },
  mounted() {
    this.getUsers();
    this.getAudit();
    mixpanel.track("page_view", {
      name_of_page_viewed: "audit_control",
    });
  },

  computed: {
    titleBeforeAudit() {
      return this.audit.mutation == this.listOfMutations.CREATE.description ? `Antes da criação` : `Antes da alteração`;
    },
    titleAfterAudit() {
      return this.audit.mutation == this.listOfMutations.CREATE.description ? `Depois da criação` : `Dados alterados`;
    },
    contentBeforeAudit() {
      return this.audit.mutation == this.listOfMutations.CREATE.description ? 'Sem dados informados' : this.audit.newValue;
    },
    contentAfterAudit() {
      return this.audit.mutation == this.listOfMutations.CREATE.description ? this.audit.newValue : this.audit.diff;
    },

    minimumDate() {
      return new Date().toISOString();
    },
    showCustomer() {
      return this.audit.entityId == this.listOfEntities.CUSTOMER.id;
    }
  },

  methods: {
    previewAudit(audit) {
      this.modalPreviewAudit = true;
      this.audit = audit;
    },
    closeModalPreview() {
      this.modalPreviewAudit = false;
    },
    getUsers() {
      apiUser
        .findAll()
        .then((response) => {
          this.users = response.data.map((u) => new UserModel(u));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getAudit() {
      apiAudit
        .findAll()
        .then((response) => {
          this.audits = response.data.map((a) => new AuditModel(a));

          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  height: 300px;
  overflow-x: auto;
}
</style>
