<template>
  <div>
    <v-text-field
      outlined
      dense
      :maxlength="isInternationalPhone ? 25 : 16"
      v-mask="mask"
      inputmode="decimal"
      :label="labelField"
      v-model="phoneData"
      :rules="rules"
    />
  </div>
</template>

<script>
export default {
  name: "PhoneField",
  model: {
    prop: "model",
    event: "onChange",
  },
  props: {
    model: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    isInternationalPhone: {
      type: Boolean,
    },
    isLandline: {
      type: Boolean,
    },
    rules: { type: Array, required: false },
  },
  data() {
    return {
      phoneData: this.model,
      labelField: this.label,
    };
  },
  computed: {
    mask() {
      if (this.isInternationalPhone) {
        return '';
      }

      if (this.isLandline) {
        return '(##)####-####';
      }

      return '(##)#####-####';
    }
  },
  watch: {
    phoneData() {
      this.$emit("onChange", this.phoneData);
    },
  },
  methods: {
    // TODO: Ver com Diogo
    // removeMask() {
    //   this.phoneData = this.phoneData
    //     .replace("(", "")
    //     .replace(")", "")
    //     .replaceAll(".", "");
    // },
  },
};
</script>

<style>
</style>