import PersonType from "./PersonType";
import { PERSON_TYPE, REGISTER_TYPE } from '../constants/generic/types';
import RegisterType from "./RegisterType";

export default class Person {
  constructor(obj) {
    obj = obj || {};

    this.personType = new PersonType(obj.personType);
    this.registerType = new RegisterType(obj.registerType);
  }

  setPersonType() {
    if (this.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id) {
      this.personType = PERSON_TYPE.LEGAL;
    } else {
      this.personType = PERSON_TYPE.PHYSICAL;
    }
  }

  isPJ() {
    return !!(this.personType.id == PERSON_TYPE.LEGAL.id);
  }

  isPF() {
    return !!(this.personType.id == PERSON_TYPE.PHYSICAL.id);
  }

  isInternationalAgent() {
    return !!(this.personType.id == PERSON_TYPE.INTERNATIONAL_AGENT.id);
  }
}