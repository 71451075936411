import CorrespondentModel from './correspondent-model';

export default class AgentCommissionAccountModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.balance = obj.balance;
    this.lastPayment = obj.lastPayment;
    this.lastCredit = obj.lastCredit;
    this.agent = new CorrespondentModel(obj.agent);
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}