<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1 class="titulo">Spread / Moeda</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            cols="12"
            md="9"
            sm="8"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="openAddCurrency"
            >
              <i class="far fa-plus" /> Adicionar
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="4"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="currencies"
                :search="search"
                sort-by="id"
                sort-asc
                :items-per-page="10"
                :custom-filter="caseInsensitiveAccentsInsensitive"
              >
                <template #[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="editCurrencyModal(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-pencil" />
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add Currency Modal -->
    <v-dialog
      persistent
      eager
      v-model="addCurrencyModal"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Moedas</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-currency"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Código"
                      v-model="currency.code"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Nome"
                      v-model="currency.name"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="currency.minimumSpreadSell"
                      outlined
                      dense
                      label="Spread Mínimo Venda Remessa(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="currency.recommendedSpreadSell"
                      outlined
                      dense
                      label="Spread Recomendado Venda Remessa(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="currency.maximumSpreadSell"
                      outlined
                      dense
                      label="Spread Máximo Venda Remessa(%)"
                      suffix="%"
                    />
                  </v-col>

                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="currency.minimumSpreadBuy"
                      outlined
                      dense
                      label="Spread Mínimo Compra Remessa(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="currency.recommendedSpreadBuy"
                      outlined
                      dense
                      label="Spread Recomendado Compra Remessa(%)"
                      suffix="%"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="4"
                  >
                    <NumberField
                      v-model="currency.maximumSpreadBuy"
                      outlined
                      dense
                      label="Spread Máximo Compra Remessa(%)"
                      suffix="%"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              v-if="!update"
              text
              class="btn-primary mr-2"
              @click="addCurrency"
            >
              Salvar
            </v-btn>
            <v-btn
              v-else-if="update"
              text
              class="btn-primary mr-2"
              @click="updateCurrency"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="addCurrencyModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiCurrency from "@/api/spread/currency-api";

// Models
import CurrencyModel from "@/model/currency-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";

// Components
import NumberField from "@/components/comum/NumberField";

export default {
  name: "CurrenciesControl",
  mixins: [mixinMessage, mixinDataTableFilters],
  components: { NumberField },
  data() {
    return {
      search: "",
      currencies: [],
      columns: [
        { text: "Código", value: "code", align: "left", sortable: true, width: "100px" },
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "Mínimo Venda Remessa(%)", value: "minimumSpreadSell", align: "center", sortable: true },
        { text: "Recomendado Venda Remessa(%)", value: "recommendedSpreadSell", align: "center", sortable: true },
        { text: "Máximo Venda Remessa(%)", value: "maximumSpreadSell", align: "center", sortable: true },
        { text: "Mínimo Compra Remessa(%)", value: "minimumSpreadBuy", align: "center", sortable: true },
        { text: "Recomendado Compra Remessa(%)", value: "recommendedSpreadBuy", align: "center", sortable: true },
        { text: "Máximo Compra Remessa(%)", value: "maximumSpreadBuy", align: "center", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      currency: new CurrencyModel(),
      addCurrencyModal: false,
      update: false,
    };
  },
  watch: {},
  mounted() {
    this.getCurrencyList();
  },
  computed: {},
  methods: {
    getCurrencyList() {
      apiCurrency
        .findAll()
        .then((response) => {
          this.currencies = response.data.map((n) => new CurrencyModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddCurrency() {
      this.update = false;
      this.currency = new CurrencyModel();
      this.addCurrencyModal = true;
    },

    editCurrencyModal(currency) {
      this.update = true;
      this.currency = new CurrencyModel(currency);
      this.addCurrencyModal = true;
    },

    addCurrency() {
      apiCurrency
        .add(this.currency)
        .then((response) => {
          this.currencies.push(new CurrencyModel(response.data));
          this.addCurrencyModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateCurrency() {
      apiCurrency
        .update(this.currency)
        .then((response) => {
          let index = this.currencies.findIndex((c) => c.id == this.currency.id);
          this.currencies.splice(index, 1, new CurrencyModel(response.data));
          this.addCurrencyModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
