const mixinExpandedDataTable = {
  data() {
    return {
      expanded: [],
    };
  },
  methods: {
    clickRow(item, slot) {
      if (slot.isExpanded) {
        const index = this.expanded.findIndex(i => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
  },
};

export default mixinExpandedDataTable;