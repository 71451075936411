<template>
  <div class="py-2">
    <v-row>
      <v-col
        class="pb-2"
        cols="12"
        md="12"
      >
        <legend>Deseja cadastrar uma conta no exterior?</legend>
        <v-radio-group
          row
          class="py-0"
          v-model="customer.additional.newInternationalAccount"
        >
          <v-radio
            :value="true"
            label="Sim"
          />
          <v-radio
            :value="false"
            label="Não"
          />
        </v-radio-group>
      </v-col>
      <v-col
        v-if="customer.additional.newInternationalAccount"
        cols="12"
        md="12"
        class="py-0"
      >
        <v-row
          v-for="(bank, index) in customer.internationalBanks"
          :key="index"
          class="pb-3"
        >
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="bank.accountName"
              outlined
              dense
              label="Apelido da conta"
              :rules="[validationIsRequiredField]"
            >
              <template #append-outer>
                <InfoTooltip label="Ex: Conta do banco XYZ" />
              </template>
            </v-text-field> 
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="bank.bank"
              outlined
              dense
              label="Banco"
              :rules="[validationIsRequiredField]"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="bank.swiftCodeBIC"
              outlined
              dense
              label="Código SWIFT ou BIC"
              :rules="[validationIsRequiredField]"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="bank.numberIBAN"
              outlined
              dense
              label="Número da Conta ou IBAN"
              :rules="[validationIsRequiredField]"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="bank.ownerName"
              outlined
              dense
              label="Nome do titular"
              :rules="[validationIsRequiredField]"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="bank.tabNumberRouting"
              outlined
              dense
              label="Número ABA/ROUTING"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="bank.forFurtherCredit"
              outlined
              dense
              label="FFC (For further credit)"
            />
          </v-col>
          <v-col
            cols="12"
            md="1"
            v-if="customer.internationalBanks.length >= 2"
            class="py-0"
          >
            <v-btn
              icon
              class="float-right"
              @click="removeBank(index)"
              text
            >
              <i class="far fa-trash" />
            </v-btn>
          </v-col>
          <v-col
            class="py-2"
            cols="12"
            md="12"
          >
            <v-checkbox
              label="Deseja cadastrar um banco intermediário"
              v-model="bank.intermediary"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
            v-if="bank.intermediary"
          >
            <v-row>
              <v-col
                class="py-0"
                cols="12"
                md="2"
              >
                <CountryField 
                  v-model="bank.intermediaryCountry" 
                  label="País" 
                  :rules="[validationIsRequiredAutocompleteText]"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="bank.intermediaryBankName"
                  outlined
                  dense
                  label="Nome banco intermediário"
                  :rules="[validationIsRequiredField]"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="bank.intermediaryAbaSwiftCode"
                  outlined
                  dense
                  label="Código SWIFT ou ABA banco intermediário"
                  :rules="[validationIsRequiredField]"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="py-0">
          <v-col class="pt-5">
            <v-btn
              class="btn-secondary float-right"
              @click="insertBank"
              text
            >
              <i class="far fa-plus" /> Adicionar outro
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Model
import CustomerInternationalBankModel from "@/model/customer-international-bank-model";
// Components
import CountryField from "@/components/comum/CountryField";
import InfoTooltip from '@/components/comum/InfoTooltip';

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PFInternationalBank",
  mixins: [ mixinValidationRules ],
  components: { CountryField, InfoTooltip },
  data() {
    return {
      customer: this.customerProp,
      listOfCountries: undefined,
    };
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  mounted() {
    if (this.customer.internationalBanks.length == 0) {
      this.customer.internationalBanks = [new CustomerInternationalBankModel()];
    }
  },
  methods: {
    insertBank() {
      this.customer.internationalBanks.push(new CustomerInternationalBankModel());
    },
    removeBank(index) {
      if (this.customer.internationalBanks.length > 1) {
        this.customer.internationalBanks.splice(index, 1);
      }
    },
  },
};
</script>

<style>
</style>