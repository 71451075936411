import UserModel from './../user-model';
export default class AuditModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.entityDescription = obj.entityDescription;
    this.entityId = obj.entityId;
    this.mutation = obj.mutation;
    this.userId = obj.userId;
    this.diff = obj.diff;
    this.oldValue = obj.oldValue;
    this.newValue = obj.newValue;
    this.user = new UserModel(obj.user);
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}