<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Dados Bancarios</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <BankComponent
              v-model="correspondent"
              has-pix
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

// Components
import BankComponent from "@/components/comum/BankComponent";

export default {
  name: "CorrespondentFinancial",
  components: { BankComponent },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: {
      type: Object,
    },
  },
  data() {
    return {
      correspondent: this.correspondentProp,
    };
  },
  watch: {
    correspondent() {
      this.$emit("onChange", this.correspondent);
    },
  },
  mounted() { },
  methods: {}
};
</script>

<style></style>
