import api from '../api';

function add(template) {
  template = template || {};

  return new Promise((resolve, reject) => {
    return api.post('/templates', template)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(template) {
  template = template || {};

  return new Promise((resolve, reject) => {
    return api.put(`/templates/${template.id}`, template)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/templates')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findById(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/templates/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function inactivate(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.delete(`/templates/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findById,
  add,
  update,
  inactivate
};