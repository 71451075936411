export default class RateApiRouteModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.name = obj.name;
    this.route = obj.route;
    this.active = !!obj.active;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}