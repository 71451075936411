import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export const OPTIONS_STORE_CONFIGURATIONS = {
  ACTIONS: {
    UPDATE_CONFIGURATION_MODEL: 'configurations/updateConfigurationsModel',
    UPDATE_CONFIGURATION_API: 'configurations/updateConfigurationsAPI'
  },
  GETTERS: {
    GET_CONFIGURATIONS: 'configurations/getConfigurations'
  },
};

export default {
  state: {
    configurations: []
  },
  actions,
  getters,
  mutations,
  namespaced: true
};