<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <v-row class="pt-4">
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="(Opcional) Cliente / CPF / CNPJ "
              :items="customerList"
              v-model="operation.customer"
              item-value="id"
              :item-text="itemTextCustomers"
              return-object
              dense
              clearable
              autofocus
              outlined
              v-disabled-icon-focus
              :filter="caseInsensitiveAccentsInsensitiveIgnoreDotsDashes"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Banco"
              :items="bankList"
              v-model="operation.bank"
              item-value="id"
              item-text="companyName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Natureza"
              :items="natures"
              v-model="operation.nature"
              item-value="id"
              item-text="exibitionName"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-autocomplete
              label="Moeda"
              :items="currencies"
              v-model="operation.currency"
              item-value="id"
              :item-text="itemTextCurrencies"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
              :rules="[validationIsRequiredAutocompleteId]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
            v-if="calculationTypeConst.CURRENCY_AMOUNT.id == calculationType"
          >
            <MoneyField
              label="Quantidade ME"
              prefix=" "
              v-model="operation.amount"
              :rules="[validationIsRequiredFieldNumeric]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
            v-else
          >
            <MoneyField
              label="Quantidade Reais"
              prefix=" "
              v-model="operation.totalValue"
              :rules="[validationIsRequiredFieldNumeric]"
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Spread"
              prefix=" "
              suffix=" %"
              v-model="operation.spread"
              persistent-hint
              :hint="spreadHint"
              :min="operation.spreadMin"
              :max="operation.spreadMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Taxa do Cliente"
              prefix="R$ "
              suffix=""
              :precision="5"
              v-model="operation.customerRate"
              persistent-hint
              :hint="customerRateHint"
              :min="operation.customerRateMin"
              :max="operation.customerRateMax"
              :rules="[validationIsRequiredFieldNumeric]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <MoneyField
              label="Tarifa"
              :prefix="operation.useUsdExchangeContract ? 'US$ ' : 'R$ '"
              suffix=""
              v-model="operation.exchangeContractCost"
              :rules="[validationIsRequiredFieldNumericAllowZero]"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
      align="right"
      class="pb-0"
    >
      <v-card class="box card-cambio">
        <v-row class="pa-5">
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">Câmbio comercial (SPOT)</h1>
            <span class="operation-value">{{ operation.currencyQuotation || 0 | formatCurrency(5) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">IOF({{ operation.iof || 0 }}%)</h1>
            <span class="operation-value">{{ operation.iofValue || 0 | formatCurrency(2) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">IR({{ operation.ir || 0 }}%)</h1>
            <span class="operation-value">{{ operation.irValue || 0 | formatCurrency(2) }}</span>
          </v-col>
          <template v-if="operation.cide">
            <v-col
              cols="12"
              md="6"
              class="text-left"
            >
              <h1 class="operation-title">CIDE({{ operation.cide || 0 }}%)</h1>
              <span class="operation-value">{{ operation.cideValue || 0 | formatCurrency(2) }}</span>
            </v-col>
          </template>
          <v-col
            cols="12"
            md="6"
            class="text-left"
          >
            <h1 class="operation-title">
              Tarifa{{ operationProp.useUsdExchangeContract ? `(${formatUSD(operationProp.exchangeContractCost)})` : `` }}
            </h1>
            <span class="operation-value">{{ operation.exchangeContractCostBrl || 0 | formatCurrency }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
            v-if="calculationTypeConst.CURRENCY_AMOUNT.id == calculationType"
          >
            <h1 class="operation-title">Total</h1>
            <span class="operation-value">{{ operation.totalValue || 0 | formatCurrency(2) }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-left"
            v-else
          >
            <h1 class="operation-title">Total</h1>
            <span class="operation-value">{{ operation.currency.code }} {{ operation.amount || 0 | formatNumber(2)
            }}</span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import MoneyField from "@/components/comum/MoneyField";

// Api
import apiCurrency from "@/api/spread/currency-api";
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";
import apiOperationNature from "@/api/configuration/operation-nature-api";
import apiCustomers from "@/api/customer/customer-api";

// Models
import CurrencyModel from "@/model/currency-model";
import ExchangeBankModel from "@/model/exchange-bank-model";
import OperationNatureModel from "@/model/operation-nature-model";
import CustomerModel from "@/model/customer-model";
import CustomerExchangeBankModel from "@/model/customer-exchange-bank-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAutoCompleteFilters from "@/mixin/mixin-autocomplete-filters";

// Constants
import { OPERATION_TYPE, OPERATION_CALCULATION_TYPE } from "@/constants/general-constants";

// Utils
import numberUtils from "../../../../common/utils/number";

export default {
  name: "SimulatorRemittance",
  mixins: [mixinMessage, mixinValidationRules, mixinAutoCompleteFilters],
  components: {
    MoneyField,
  },
  data() {
    return {
      operation: this.operationProp,
      currencies: [],
      banks: [],
      natures: [],
      calculationTypeConst: OPERATION_CALCULATION_TYPE,
      customerList: [],
      exchangeBankList: [],
    };
  },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
    calculationType: {
      type: [Number, String],
    },
  },
  filters: {
    formatCurrency(value, precison) {
      return numberUtils.formatCurrency('BRL', value, precison);
    },
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value);
    },
  },
  watch: {
    operation() {
      this.emitOperation();
    },
    "operation.bank.id"() {
      this.operation.useUsdExchangeContract = !!this.operation.bank?.useUsdExchangeContract;
      this.operation.exchangeContractCost = this.operation.bank.exchangeContractCost;
    },
    "operation.customer"() {
      if (this.operation.customer == undefined) {
        this.exchangeBankList = [];

        return;
      }

      this.getCustomerExchangeBanks();
    },

  },
  mounted() {
    this.getBankList();
    this.getNatureList();
    this.getCurrencies();
    this.getCustomersList();
  },
  computed: {
    bankList() {
      if (this.exchangeBankList.length) {
        return this.exchangeBankList.map(i => i.bank) || [];
      }

      return this.banks.filter(i => i.exchangeType.id == OPERATION_TYPE.REMMITANCE.id || i.exchangeType.id == OPERATION_TYPE.BOTH.id) || [];
    },
    itemTextCurrencies() {
      return (item) => item.code + ' - ' + item.name;
    },
    spreadHint() {
      if (this.operation.spreadMin && this.operation.spreadMax) {
        return `Min: ${this.operation.spreadMin}% - Max: ${this.operation.spreadMax}%`;
      }

      return '';
    },
    customerRateHint() {
      if (this.operation.customerRateMin && this.operation.customerRateMax) {
        return `Min: ${this.formatBRL(this.operation.customerRateMin, 5)} - Max: ${this.formatBRL(this.operation.customerRateMax, 5)}`;
      }

      return '';
    },
    itemTextCustomers() {
      return (item) => !item.name ? item.companyName + ' - ' + item.cpfCnpj : item.name + ' - ' + item.cpfCnpj;
    },
  },
  methods: {
    getCurrencies() {
      if (this.currencies[0]) {
        return;
      }

      apiCurrency.findAll()
        .then((response) => {
          this.currencies = response.data.map(
            (c) => new CurrencyModel(c)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getBankList() {
      if (this.banks[0]) {
        return;
      }

      apiExchangeBank
        .findAll()
        .then((response) => {
          this.banks = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getNatureList() {
      if (this.natures[0]) {
        return;
      }

      apiOperationNature
        .findAll()
        .then((response) => {
          this.natures = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    formatBRL(value, precision) {
      return numberUtils.formatCurrency('BRL', value, precision);
    },

    formatUSD(value) {
      return numberUtils.formatCurrency('USD', value);
    },

    emitOperation() {
      this.$emit("onChange", this.operation);
    },

    getCustomersList() {
      apiCustomers
        .findAll()
        .then((response) => {
          this.customerList = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCustomerExchangeBanks() {
      apiCustomers
        .findExchangeBanks(this.operation.customer.id, this.operation.exchangeType.id)
        .then((response) => {
          this.exchangeBankList = response.data.map((c) => new CustomerExchangeBankModel(c)).filter(i => i.limit.id);
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.operation-title {
  text-align: left;
  font-size: 0.9rem;
}

.operation-value {
  font-size: 1.3rem;
  text-align: right;
  color: var(--v-primary-base);
}
</style>
