
function get(user, resetPasswordCode) {
  return [
    {
      name: "Usuário",
      children: [
        {
          name: "Nome",
          attribute: "Nome_usuario",
          title: "Nome do usuário",
          inline: true,
          content: () => {
            return user?.name || '';
          }
        },
        {
          name: "Código de redefinição de senha",
          attribute: "Codigo_redefinicao_senha",
          title: "Código de redefinição de senha",
          inline: true,
          content: () => {
            return resetPasswordCode || '';
          }
        },
        {
          name: "E-mail",
          attribute: "Email_usuario",
          title: "E-mail do usuário",
          inline: true,
          content: () => {
            return user?.email || '';
          }
        }
      ]
    }
  ]
}
export default { get }