'use strict';

// Obtem as variaveis do common.
import composer from '../../../../common/plugins/variable-composer/variable-composer';

const NOME_PLUGIN = "inserirdados";
const NOME_PLUGIN_AUTO_COMPLETE = 'inserirdados-auto-complete';

let variablesCreatedBySystem = composer.getSystemVariables('');


let variablesAutoComplete = mountVariablesToAutoComplete(variablesCreatedBySystem);

function mountVariablesToAutoComplete(variablesCreatedBySystem) {
  let data = [];
  variablesCreatedBySystem.map((v) => {
    v.children.map(item => {
      data.push({
        text: `${v.name} > ${item.name}`,
        value: `${item.identificador}|${item.inline}|${item.attribute}|${item.title}|${item.content}`
      });
    });
  });

  return data;
}

function createLayoutVariable(variable) {

  let layoutVariableBlock = `
  border: 1.5px dashed #696969;
  color: #696969;
  background-color: rgba(101,76,240,0.25);
  padding: 25px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  display: block;`;

  let layoutVariavelInline = `
  border: 1.5px dashed #696969;
  color: #696969;
  background-color: #fafafa;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 1px;
  text-align: center;
  font-weight: 500;
  width: initial;
  display: inline-flex;
  font-size: 13px;
  `;

  return `&nbsp;<span custom-variable=@@${variable.attribute.toLowerCase()}@@ 
   style="${(variable.inline) ? layoutVariavelInline : layoutVariableBlock}" contenteditable=false>` +
    `${variable.title.replace('_', ' ').replace('_', ' ').replace('_', ' ').replace('_', ' ')} </span>&nbsp;`;
}

function createPluginAutoComplete(variablesAutoComplete) {
  // Plugin auto-complete para inserir variáveis no editor.
  // eslint-disable-next-line no-undef
  tinymce.PluginManager.add(NOME_PLUGIN_AUTO_COMPLETE, function (editor) {

    editor.ui.registry.addAutocompleter(NOME_PLUGIN_AUTO_COMPLETE, {
      ch: '$', // o caractere acionador para abrir o preenchimento automático
      minChars: 2, // quantidade de caracteres para iniciar a pesquisa após o "ch".
      columns: 1,  // deve ser 1 para resultados baseados em texto
      fetch: (filtro) => {

        //Filtra dinamicamente as variáveis na pesquisa.
        let results = variablesAutoComplete.filter(v => v.text.toLowerCase().indexOf(filtro.toLowerCase()) > -1);

        // eslint-disable-next-line no-undef
        return new tinymce.util.Promise((resolve) => resolve(results));
      },
      onAction: function (autocompleteApi, rng, value) {
        let parts = value.split('|');
        //Montando a variável para adicionar no Editor.
        let variable = {
          identificador: parts[0],
          inline: (parts[1] === 'true'),
          attribute: parts[2],
          title: parts[3],
          content: parts[4]
        };

        // inserindo os dados no editor
        editor.selection.setRng(rng);
        editor.insertContent(createLayoutVariable(variable));

        // escondendo a lista de variáveis.
        autocompleteApi.hide();
      }
    });
  });
}

function getVariablesInTree(editor, variablesCreatedBySystem) {
  return (variablesCreatedBySystem.length < 1) ?
    [{
      type: 'menuitem',
      text: "Não há informações disponiveis",
      disabled: true,
    }] :
    variablesCreatedBySystem.map((g) => {
      return {
        type: 'nestedmenuitem',
        text: g.name,
        tooltip: g.name,
        value: g.name,
        getSubmenuItems: function () {
          return g.children.map((v) => {
            return {
              type: "menuitem",
              text: v.name,
              onAction: function () {
                editor.insertContent(createLayoutVariable(v));
              }
            };
          });
        }
      };
    });
}

function createPluginVariablesInTree(variablesCreatedBySystem) {
  // Plugin para apresentar as variáveis em formato de arvore.
  // eslint-disable-next-line no-undef
  tinymce.PluginManager.add(NOME_PLUGIN, function (editor) {

    let systemVariables = getVariablesInTree(editor, variablesCreatedBySystem);

    editor.ui.registry.addMenuButton(NOME_PLUGIN, {
      text: 'Variáveis',
      icon: "library_add",
      fetch: function (callback) {
        callback(systemVariables);
      }
    });
  });
}

async function init() {
  // eslint-disable-next-line no-undef
  if (tinyMCE.PluginManager.get(NOME_PLUGIN)) {
    return;
  }

  // eslint-disable-next-line no-undef
  if (tinyMCE.PluginManager.get(NOME_PLUGIN_AUTO_COMPLETE)) {
    return;
  }

  createPluginVariablesInTree(variablesCreatedBySystem);
  createPluginAutoComplete(variablesAutoComplete);
}

export default { init };