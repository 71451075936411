<template>
  <v-row>
    <v-col
      cols="12"
      md=""
    >
      <v-card class="box card-cambio">
        <OperationalDocuments v-model="operation" />
        <CustomerOperationalDocuments v-model="operation" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CustomerOperationalDocuments from "./CustomerOperationalDocuments";
import OperationalDocuments from "./OperationalDocuments";

export default {
  name: "OperationDocuments",
  mixins: [],
  components: { CustomerOperationalDocuments, OperationalDocuments },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  data() {
    return {
      operation: this.operationProp,
    };
  },
  mounted() { },
  watch: {  },
  methods: {  },
};
</script>

<style scoped></style>
