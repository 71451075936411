import api from '../api';

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get(`/currencies`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function add(currency) {
  currency = currency || {};

  return new Promise((resolve, reject) => {
    return api.post('/currencies', currency)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(currency) {
  currency = currency || {};

  return new Promise((resolve, reject) => {
    return api.put(`/currencies/${currency.id}`, currency)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/currencies/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  add,
  update,
  findId,
};