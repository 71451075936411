<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Acordo de Usuário</h1>
        <v-divider class="py-3" />
        <v-col
          cols="12"
          md="12"
        >
          <h2>
            ATENÇÃO, AO PROSSEGUIR COM O CADASTRO VOCÊ ESTÁ CONCORDANDO COM OS
            SEGUINTES TERMOS:
          </h2>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <div class="terms">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                (Nos termos da Instrução 301 da Comissão de Valores Mobiliários, o
                cliente acima qualificado declara ser “Pessoa vinculada” à Cambio
                hoje e seus parceiros devidamente autorizados junto ao Banco
                Central do Brasil. Caso necessário, autorizo a Cambio Hoje, sob
                minha expressa solicitação, realizar meu cadastro em diferentes
                instituições financeiras com intuito específico em operações de
                câmbio.)
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                Declaro que as informações acima, são a expressão da verdade, me
                responsabilizando pelas mesmas, sob pena de aplicação do
                dispositivo nº 64 da Lei 8.383, de 30 de Dezembro de 1991.
                Autorizo ainda à Cambio Hoje, por seus prepostos, a consultar as
                informações consolidadas de risco em operações de crédito e
                coobrigações prestadas na Central de Riscos do Banco Central do
                Brasil. Em consonância com o que dispõe a Circular 3.461 do Banco
                Central do Brasil e a Instrução 301 da Comissão de valores
                mobiliários. Declaro que pretendo manter relação de negócios com a
                Cambio Hoje de natureza financeira e com o seguinte propósito: *
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                Declaro, nos termos da Instrução 301 da Comissão de Valores
                Mobiliários, que: Não estou impedido de operar no mercado de
                valores mobiliários;(II) Minhas ordens devem ser transmitidas por
                escrito, por sistemas eletrônicos de conexões automatizadas ou
                telefone e outros sistemas de transmissão de voz;(III) Assumo o
                compromisso de comunicar expressa e imediatamente à Cambio Hoje,
                qualquer alteração nas declarações e informações aqui prestadas,
                bem como, a atender os procedimentos necessários para a
                atualização de meu cadastro nesta instituição. *
              </v-col>
            </v-row>
            <v-row v-if="!isExchange">
              <v-col
                cols="12"
                md="12"
              >
                Caso exista algum erro nos dados bancários informados acima,
                haverá MULTA PENITENCIAL para que os dados sejam corrigidos junto
                ao banco pagador e seja refeita a ordem
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                Em caso de cancelamento da ordem, pode haver MULTA PENITENCIAL de
                acordo com o custo da revenda do moeda comprada no ato do
                fechamento da operação.
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-checkbox
                label="Concordo com os termos"
                v-model="agreeToTerms"
                @change="$emit('change', agreeToTerms)"
                dense
                :rules="[validationIsRequiredCheckBox]"
              />
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-col>
  </v-row>
</template>

<script>

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "CustomerUserAgreement",
  mixins: [ mixinValidationRules ],
  model: {
    prop: "agreeToTermsProp",
    event: "change",
  },
  props: {
    isExchange: {
      type: Boolean,
    },
    agreeToTermsProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      agreeToTerms: this.agreeToTermsProp,
    };
  },
};
</script>

<style>
.terms {
  height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #efefef;
  padding: 20px;
}
</style>