
const TYPE_ENVIRONMENT = {
    HTML: "HML",
    PROD: "PROD"
};

const ENTITY_TYPE = {
    USER: { DESCRIPTION: "USER" },
    CUSTOMER: { DESCRIPTION: "CUSTOMER" },
}

module.exports = {
    TYPE_ENVIRONMENT,
    ENTITY_TYPE
}