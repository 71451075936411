<template>
  <div>
    <v-row
      v-for="(bank, index) in customer.nationalBanks"
      :key="index"
    >
      <v-col
        cols="12"
        md="11"
      >
        <BankComponent
          v-model="customer.nationalBanks[index]"
          required
          has-pix
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
        v-if="customer.nationalBanks.length >= 2"
        class="py-0"
      >
        <v-btn
          icon
          class="float-right"
          @click="removeBank(index)"
          text
        >
          <i class="far fa-trash" />
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="py-0">
      <v-col class="pt-5">
        <v-btn
          class="btn-secondary float-right"
          @click="insertBank"
          text
        >
          <i class="far fa-plus" /> Adicionar outro
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Model
import CustomerNationalBankModel from "@/model/customer-national-bank-model";

// Components
import BankComponent from "@/components/comum/BankComponent";

export default {
  name: "CustomerBank",
  mixins: [],
  components: { BankComponent },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
  watch: {
    customer: {
      handler() {
        this.$emit("onChange", this.customer);
      },
    },
  },
  computed:{  },
  mounted() {
    if (this.customer.nationalBanks.length == 0) {
      this.customer.nationalBanks = [new CustomerNationalBankModel()];
    }
  },
  methods: { 
    insertBank() {
      this.customer.nationalBanks.push(new CustomerNationalBankModel());
    },
    removeBank(index) {
      if (this.customer.nationalBanks.length > 1) {
        this.customer.nationalBanks.splice(index, 1);
      }
    },
  },
};
</script>

<style></style>
