<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1 class="titulo">Naturezas de Operação</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            cols="12"
            md="9"
            sm="8"
          >
            <v-btn
              class="btn-primary ml-0"
              text
              @click="openAddNatureModal"
            >
              <i class="far fa-plus" /> Adicionar
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            md="3"
            sm="4"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :headers="columns"
                :items="natures"
                :search="search"
                sort-by="name"
                sort-asc
                :items-per-page="10"
                :custom-filter="caseInsensitiveAccentsInsensitive"
              >
                <template #[`item.iof`]="{ item }">
                  <template v-if="item.iof">
                    {{ item.iof | formatNumber }}
                  </template>
                </template>
                <template #[`item.ir`]="{ item }">
                  <template v-if="item.ir">
                    {{ item.ir | formatNumber }}
                  </template>
                </template>
                <template #[`item.cide`]="{ item }">
                  <template v-if="item.cide">
                    {{ item.cide | formatNumber }}
                  </template>
                </template>
                <template #[`item.iofBuy`]="{ item }">
                  <template v-if="item.iofBuy">
                    {{ item.iofBuy | formatNumber }}
                  </template>
                </template>
                <template #[`item.irBuy`]="{ item }">
                  <template v-if="item.irBuy">
                    {{ item.irBuy | formatNumber }}
                  </template>
                </template>
                <template #[`item.cideBuy`]="{ item }">
                  <template v-if="item.cideBuy">
                    {{ item.cideBuy | formatNumber }}
                  </template>
                </template>
                <template #[`item.useDiDue`]="{ item }">
                  <template v-if="item.useDiDue">
                    <i class="far fa-check-circle fa-lg" />
                  </template>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        text
                        color="primary"
                        @click="editNatureModal(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-pencil" />
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add nature Modal -->
    <v-dialog
      persistent
      eager
      v-model="addNatureModal"
      max-width="80vw"
      :key="key"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Naturezas de Operação</h1>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-5">
          <v-row class="py-5">
            <v-col
              cols="12"
              md="12"
            >
              <v-form
                id="add-nature"
                ref="form"
              >
                <v-row>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="1"
                  >
                    <NumberField
                      v-model="nature.code"
                      outlined
                      dense
                      label="Código"
                      :precisao-decimal="0"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="9"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Nome"
                      v-model="nature.name"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <legend>Usa DI/DUE?</legend>
                    <v-radio-group
                      row
                      v-model="nature.useDiDue"
                      class="py-0"
                      dense
                    >
                      <v-radio
                        :value="true"
                        label="SIM"
                      />
                      <v-radio
                        :value="false"
                        label="NÃO"
                      />
                    </v-radio-group>
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <NumberField
                      v-model="nature.iofBuy"
                      outlined
                      dense
                      label="IOF Compra(%)"
                      suffix="%"
                      :precisao-decimal="6"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <NumberField
                      v-model="nature.iof"
                      outlined
                      dense
                      label="IOF Venda(%)"
                      suffix="%"
                      :precisao-decimal="6"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <NumberField
                      v-model="nature.irBuy"
                      outlined
                      dense
                      label="IR Compra(%)"
                      suffix="%"
                      :precisao-decimal="6"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <NumberField
                      v-model="nature.ir"
                      outlined
                      dense
                      label="IR Venda(%)"
                      suffix="%"
                      :precisao-decimal="6"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <NumberField
                      v-model="nature.cide"
                      outlined
                      dense
                      label="CIDE Venda(%)"
                      suffix="%"
                      :precisao-decimal="6"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="2"
                  >
                    <NumberField
                      v-model="nature.cideBuy"
                      outlined
                      dense
                      label="CIDE Compra(%)"
                      suffix="%"
                      :precisao-decimal="6"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      auto-grow
                      outlined
                      rows="1"
                      v-model="nature.observation"
                      label="Observações"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="12"
                    md="12"
                  >
                    <BilateralAgreementCountries v-model="nature" />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              v-if="!update"
              text
              class="btn-primary mr-2"
              @click="addNature"
            >
              Salvar
            </v-btn>
            <v-btn
              v-else-if="update"
              text
              class="btn-primary mr-2"
              @click="updateNature"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="addNatureModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiOperationNature from "@/api/configuration/operation-nature-api";

// Components
import NumberField from "@/components/comum/NumberField";
import BilateralAgreementCountries from "./BilateralAgreementCountries";

// Models
import OperationNatureModel from "@/model/operation-nature-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";

// Utils
import numberUtils from "../../../../../common/utils/number";

export default {
  name: "OperationNaturesControl",
  mixins: [mixinMessage, mixinDataTableFilters],
  components: {
    NumberField,
    BilateralAgreementCountries,
  },
  data() {
    return {
      search: "",
      natures: [],
      columns: [
        { text: "Código", value: "code", align: "left", sortable: true, width: "100px" },
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "IOF Compra(%)", value: "iofBuy", align: "center", sortable: true },
        { text: "IOF Venda(%)", value: "iof", align: "center", sortable: true },
        { text: "IR Compra(%)", value: "irBuy", align: "center", sortable: true },
        { text: "IR Venda(%)", value: "ir", align: "center", sortable: true },
        { text: "CIDE Compra(%)", value: "cideBuy", align: "center", sortable: true },
        { text: "CIDE Venda(%)", value: "cide", align: "center", sortable: true },
        { text: "D.I./D.U.E.", value: "useDiDue", align: "center", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      nature: new OperationNatureModel(),
      addNatureModal: false,
      update: false,
      key: 0,
    };
  },
  watch: {},
  mounted() {
    this.getNatureList();
  },
  computed: {},
  filters: {
    formatNumber(value) {
      return numberUtils.formatNumber(value);
    },
  },
  methods: {
    getNatureList() {
      apiOperationNature
        .findAll()
        .then((response) => {
          this.natures = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    openAddNatureModal() {
      this.update = false;
      this.nature = new OperationNatureModel();
      this.addNatureModal = true;
    },

    editNatureModal(nature) {
      this.update = true;

      if (nature.bilateralAgreementCountries.length) {
        this.nature = new OperationNatureModel(nature);

        return;
      }

      this.getBilateralAgreementCountries(nature.id);
      this.addNatureModal = true;
    },

    addNature() {
      apiOperationNature
        .add(this.nature)
        .then((response) => {
          this.natures.push(new OperationNatureModel(response.data));
          this.addNatureModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    updateNature() {
      apiOperationNature
        .update(this.nature)
        .then((response) => {
          let index = this.natures.findIndex((s) => s.id == this.nature.id);
          this.natures.splice(index, 1, new OperationNatureModel(response.data));
          this.addNatureModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getBilateralAgreementCountries(natureId) {
      apiOperationNature
        .findId(natureId)
        .then((response) => {
          this.nature = new OperationNatureModel(response.data);
          this.key = this.key + 1;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    }
  },
};
</script>

<style scoped></style>
