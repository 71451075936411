import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// It's important to make sure that next() is called exactly once in any given pass through the navigation guard
router.beforeEach((to, from, next) => {
  const logged = localStorage.getItem('token-tc') != null;

  if (to.name == "Login" && logged) {
    next({ name: 'Dashboard' });

    return;
  }

  if (to.name == "Assinatura" ||
    to.name == "Login" ||
    to.name == "ConfirmacaoAssinatura") {
    next();

    return;
  }

  const matched = to.matched;

  if (matched.length == 0) {
    if (logged) {
      next({ name: 'PageNotFound' });
    } else {
      next({ name: 'Login' });
    }

    return;
  }

  const requireAuth = matched.some(record => record.meta.requireAuth);

  if (requireAuth) {
    if (logged) {
      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }

});



export default router;

