import api from '../api';

function add(templateType) {
  templateType = templateType || {};

  return new Promise((resolve, reject) => {
    return api.post('/template-types', templateType)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(templateType) {
  templateType = templateType || {};

  return new Promise((resolve, reject) => {
    return api.put(`/template-types/${templateType.id}`, templateType)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/template-types')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.get(`/template-types/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function inactivate(id) {
  id = id || {};

  return new Promise((resolve, reject) => {
    return api.delete(`/template-types/${id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
  inactivate
};