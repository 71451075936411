import DocumentTypeModel from "./document-type-model";

export default class CommissionPaymentOrderDocumentModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.paymentOrderId = obj.paymentOrderId;
    this.documentType = new DocumentTypeModel(obj.documentType);
    this.path = obj.path;
    this.size = obj.size;
    this.document = obj.document;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}