<template>
  <div>
    <v-card class="card-cambio pa-5 my-5">
      <h2>{{ title }}</h2>
      <v-divider class="pb-5" />

      <v-row
        v-for="(config, i) in configurations"
        :key="i"
      >
        <v-col
          cols="12"
          md="6"
          class="pb-0"
        >
          <div>Descrição</div>
          <h3>{{ configurations[i].title }}</h3>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pb-0"
        >
          <v-autocomplete 
            v-model="configurations[i].value"
            label="Template"
            placeholder="Selecione um template ..."
            :items="templates"
            outlined
            densed
            item-value="id"
            item-text="description"
            @change="updateConfigurationList"
          />
        </v-col>
        <v-col
          cols="12"
          md="12"
          class="pt-0"
        >
          <v-divider />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import mixinMessage from "@/mixin/mixin-message";
import mixinConfigurationsPage from "@/mixin/mixin-configurations-page";
import apiTemplates from "@/api/generic/template-api";
import TemplateModel from "@/model/generic/template-model";

export default {
  name: 'TemplateConfigurationItemPage',
  mixins: [mixinMessage, mixinConfigurationsPage],
  props: {
    title: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      templates: [],
    };
  },
  mounted() {
    this.getTemplateList();
  },
  watch: {
    page() {
      this.getConfigurationsListForPage(this.page);
    },
  },
  methods: {
    getTemplateList() {
      apiTemplates
        .findAll()
        .then((response) => {
          this.templates = response.data.map((t) => new TemplateModel(t));
          this.getConfigurationsListForPage(this.page);
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>
