<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Sócios e Acionistas</h1>
        <v-divider class="py-3" />
        <v-row
          v-for="(item, index) in correspondent.partnersShareholders"
          :key="index"
        >
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-checkbox
              label="Pessoa Jurídica?"
              v-model="item.isLegalPerson"
              dense
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <v-text-field
              outlined
              dense
              :label="item.isLegalPerson ? 'Razão Social' : 'Nome'"
              v-model="item.name"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <CpfOrCnpjField
              :is-p-j="item.isLegalPerson"
              v-model="item.cpfCnpj"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-text-field
              outlined
              dense
              inputmode="decimal"
              max-lenght="3"
              label="% de participação"
              v-model="item.share"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="1"
            v-if="correspondent.partnersShareholders.length >= 2"
          >
            <v-btn
              icon
              class="float-right"
              @click="removeOtherPartners(index)"
              text
            >
              <i class="far fa-trash" />
            </v-btn>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="py-0">
          <v-col class="pt-5">
            <v-btn
              class="btn-secondary float-right"
              @click="insertOtherPartners"
              text
            >
              <i class="far fa-plus" /> Adicionar
              outro
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField.vue";

// Model
import CorrespondentPartnerShareholderModel from "@/model/correspondent-partner-shareholder-model";

export default {
  name: "CorrespondentPartnersShareholders",
  components: { CpfOrCnpjField },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: { type: Object, },
  },
  data() {
    return {
      correspondent: this.correspondentProp,
    };
  },
  watch: {
    correspondent() {
      this.$emit("onChange", this.correspondent);
    },
  },
  methods: {
    insertOtherPartners() {
      this.correspondent.partnersShareholders.push(new CorrespondentPartnerShareholderModel());
    },
    removeOtherPartners(index) {
      if (this.correspondent.partnersShareholders.length > 1) {
        this.correspondent.partnersShareholders.splice(index, 1);
      }
    },
  },
  mounted() {
    if (this.correspondent.partnersShareholders.length == 0) {
      this.correspondent.partnersShareholders = [new CorrespondentPartnerShareholderModel()];
    }
  },
};
</script>

<style></style>