<template>
  <v-autocomplete
    :label="label"
    :items="listOfUFs"
    clearable
    dense
    v-model="ufData"
    outlined
    hint="Este campo é obrigatório"
    hide-selected
    v-disabled-icon-focus
    :rules="rules"
  />
</template>

<script>
// API
import apiIBGE from "@/api/general/ibge-api";

export default {
  name: "UFField",
  model: {
    prop: "entrada",
    event: "onChange",
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    entrada: { type: String, default: undefined },
    rules: { type: Array, required: false },
  },
  data() {
    return {
      listOfUFs: undefined,
      ufData: this.entrada,
    };
  },
  mounted() {
    this.getUF();
  },
  watch: {
    ufData() {
      this.$emit("onChange", this.ufData);
    },
    entrada(){
      this.ufData = this.entrada;
    }
  },
  methods: {
    getUF() {
      apiIBGE.getState().then((resposta) => {
        this.listOfUFs = resposta.data.map((uf) => uf.sigla);
      });
    },
  },
};
</script>

<style></style>
