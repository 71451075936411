<template>
  <div>
    <TemplateConfigurationItemPage
      v-if="this.configurationMenu.page"
      :page="this.configurationMenu.page"
      :title="this.configurationMenu.name"
    />
  </div>
</template>

<script>
import TemplateConfigurationItemPage from './TemplateConfigurationItemPage';
import { PAGES_MENU_CONFIGURATION } from '@/constants/configurations-constants';
import ConfigurationMenuModel from '@/model/generic/configuration-menu-model';

export default {
  name: 'TemplateConfiguration',
  components: {
    TemplateConfigurationItemPage
  },
  model: {
    prop: "configurationMenuProp",
    event: "onChange",
  },
  props: {
    configurationMenuProp: {
      type: Object,
      required: false,
      default: () => new ConfigurationMenuModel()
    }
  },
  data() {
    return {
      configurationMenu: new ConfigurationMenuModel(),
      pages: PAGES_MENU_CONFIGURATION
    };
  },
  mounted() {
    this.configurationMenu = this.configurationMenuProp;
  },
  watch: {
    configurationMenuProp(value) {
      this.configurationMenu = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>