<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1 class="titulo">Câmbio Turismo</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-form id="filters-form">
              <v-row>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                >
                  <Datepicker
                    v-model="filters.startDate"
                    label="Data de Início"
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                >
                  <Datepicker
                    v-model="filters.endDate"
                    label="Data de Fim"
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                >
                  <v-autocomplete
                    label="Tipo"
                    :items="listExchangeType"
                    item-text="label"
                    v-model="filters.exchangeBuy"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                >
                  <v-autocomplete
                    label="Moeda"
                    :items="currencyList"
                    item-text="name"
                    item-value="id"
                    v-model="filters.currencyId"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                >
                  <v-autocomplete
                    label="Banco"
                    :items="bankList"
                    item-text="fantasyName"
                    item-value="id"
                    v-model="searchBankId"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                >
                  <v-autocomplete
                    label="Corban"
                    :items="agentList"
                    item-text="name"
                    item-value="id"
                    v-model="filters.agentId"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                >
                  <v-autocomplete
                    label="Tipo de Pessoa"
                    :items="personTypeList"
                    item-text="description"
                    item-value="id"
                    v-model="filters.idPersonType"
                    clearable
                    dense
                    outlined
                    v-disabled-icon-focus
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="3"
                  sm="6"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="filters.cpfCnpj"
                    label="Cpf/Cnpj"
                    v-mask="filters.cpfCnpj?.length > 14 ? '##.###.###/####-##' : '###.###.###-##'"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn
                    class="btn-primary float-right"
                    text
                    @click="getOperations"
                  >
                    <i class="far fa-search" />
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            lg="3"
            sm="6"
            offset-lg="9"
            offset-sm="6"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
          </v-col>
          <v-col
            v-if="!operations.length"
            cols="12"
            md="12"
          >
            <EmptyTable
              title="Nenhum registro encontrado"
              icon="far fa-hand-holding-usd"
              subtitle="Não há operações de Câmbio Turismo para o período selecionado."
            />
          </v-col>
          <v-col
            v-else
            cols="12"
            md="12"
          >
            <v-card class="card-cambio">
              <v-data-table
                :expanded.sync="expanded"
                show-expand
                :headers="columns"
                :items="operations"
                :search="search"
                sort-by="effectiveOperationDate"
                sort-desc
                :items-per-page="10"
                :custom-filter="caseInsensitiveAccentsInsensitive"
                @click:row="clickRow"
              >
                <template #[`item.effectiveOperationDate`]="{ item }">
                  <span>{{ item.effectiveOperationDate | dateTime }}</span>
                </template>

                <template #[`item.customer.name`]="{ item }">
                  <span v-if="item.customer.isPJ()">{{ item.customer.companyName }}</span>
                  <span v-else>{{ item.customer.name }}
                  </span>
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="primary"
                        @click="goToCustomer(item.customer.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="far fa-eye" />
                      </v-btn>
                    </template>
                    <span>Visualizar cliente</span>
                  </v-tooltip>
                </template>

                <template #[`item.exchangeBuy`]="{ item }">
                  <v-chip
                    v-if="item.exchangeBuy"
                    small
                    label
                    color="red lighten-4"
                  >
                    Compra
                  </v-chip>
                  <v-chip
                    v-else
                    small
                    label
                    color="teal lighten-4"
                  >
                    Venda
                  </v-chip>
                </template>

                <template #[`item.amount`]="{ item }">
                  <span>{{ item.amount | formatCurrency(item.currency.code) }}</span>
                </template>
                <template #[`item.deliveryType.description`]="{ item }">
                  <span v-if="item.deliveryType.id == deliveryTypes.DELIVERY.id">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <i
                          class="far fa-truck"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Delivery</span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <i
                          class="far fa-store"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>Retirada em loja</span>
                    </v-tooltip>
                  </span>
                </template>

                <template #[`item.bankRate`]="{ item }">
                  <span>{{ item.bankRate | formatCurrency('BRL', 5) }}</span>
                </template>

                <template #[`item.customerRate`]="{ item }">
                  <span>{{ item.customerRate | formatCurrency('BRL', 5) }}</span>
                </template>

                <template #[`item.iofValue`]="{ item }">
                  <span>{{ item.iofValue | formatCurrency('BRL', 5) }}</span>
                </template>

                <template #[`item.totalValue`]="{ item }">
                  <span>{{ item.totalValue | formatCurrency('BRL') }}</span>
                </template>

                <template #[`item.bankSlip`]="{ item }">
                  <DataTableDocumentInput
                    download-file-name="Boleto"
                    :callback-add-document="addDocument"
                    v-model="item.bankSlip"
                    :callback-download-document="downloadDocument"
                  />
                </template>

                <template #[`item.paySlip`]="{ item }">
                  <DataTableDocumentInput
                    download-file-name="Comprovante"
                    :callback-add-document="addDocument"
                    v-model="item.paySlip"
                    :callback-download-document="downloadDocument"
                  />
                </template>

                <template #[`item.authDocument`]="{ item }">
                  <DataTableDocumentInput
                    download-file-name="Autorização"
                    :callback-add-document="addDocument"
                    v-model="item.authDocument"
                    :callback-download-document="downloadDocument"
                  />
                </template>

                <!-- Expanded Content -->
                <template #expanded-item="{ item }">
                  <td :colspan="columns.length + 1">
                    <v-card class="box card-cambio my-5">
                      <v-row>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <span class="expand-title">Nº da OP</span>
                          <p class="expand-value">{{ item.id }}</p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <span class="expand-title">Taxa sem IOF</span>
                          <p class="expand-value">{{ item.bankRate || 0 | formatCurrency('BRL', 5) }}</p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <span class="expand-title">Taxa do Cliente</span>
                          <p class="expand-value">{{ item.customerRate || 0 | formatCurrency('BRL', 5) }}</p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <span class="expand-title">Subtotal</span>
                          <p class="expand-value">{{ item.totalValue || 0 | formatCurrency('BRL') }}</p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <span class="expand-title">VET
                            <InfoTooltip label="Valor efetivo total" />
                          </span>
                          <p class="expand-value">{{ item.vet || 0 | formatCurrency('BRL', 5) }}</p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                          v-if="item.deliveryType.id == deliveryTypes.DELIVERY.id"
                        >
                          <span class="expand-title">Custo de delivery</span>
                          <p class="expand-value">{{ item.deliveryCost || 0 | formatCurrency('BRL') }}</p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span class="expand-title">Entrega</span>
                          <p class="expand-value">
                            {{ item.deliveryType.description }} - {{ item.deliveryDate | date }}
                          </p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span class="expand-title">Corban</span>
                          <p class="expand-value">{{ item.agent.name }}</p>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span class="expand-title">Banco</span>
                          <p class="expand-value">{{ item.bank.fantasyName }}</p>
                        </v-col>
                      </v-row>
                    </v-card>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Api
import apiOperation from "@/api/operation/operation-api";
import apiCurrency from "@/api/spread/currency-api";
import apiExchangeBank from "@/api/exchangeBank/exchange-bank-api";
import apiCorrespondent from "@/api/correspondent/correspondent-api";
import apiOperationDocuments from "@/api/operation/operation-documents-api";

// Models
import OperationModel from "@/model/operation-model";
import OperationFiltersModel from "@/model/operation-filters-model";
import CurrencyModel from "@/model/currency-model";
import ExchangeBankModel from "@/model/exchange-bank-model";
import CorrespondentModel from "@/model/correspondent-model";
import OperationDocumentModel from "@/model/operation-document-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinDataTableFilters from "@/mixin/mixin-data-table-filters";
import mixinExpandedDataTable from "@/mixin/mixin-data-table-expanded";

// Utils
import dateUtils from "../../../../common/utils/date";
import numberUtils from "../../../../common/utils/number";

// Components
import Datepicker from "@/components/comum/Datepicker.vue";
import EmptyTable from "@/components/comum/EmptyTable.vue";
import DataTableDocumentInput from "@/components/comum/DataTableDocumentInput.vue";
import InfoTooltip from "@/components/comum/InfoTooltip.vue";

// Constants
import { PERSON_TYPE, DELIVERY_TYPE, OPERATION_DOCUMENT_TYPES } from "@/constants/general-constants";
import mixpanel from "mixpanel-browser";

export default {
  name: "ExchangeOperationReport",
  mixins: [mixinMessage, mixinDataTableFilters, mixinExpandedDataTable],
  components: { Datepicker, EmptyTable, DataTableDocumentInput, InfoTooltip },
  data() {
    return {
      search: "",
      operations: [],
      columns: [
        { text: "Data", value: "effectiveOperationDate", align: "left", sortable: true, width: "180px" },
        { text: "Cpf/Cnpj", value: "customer.cpfCnpj", align: "left", sortable: true, width: "160px" },
        { text: "Cliente / Razão Social", value: "customer.name", align: "left", sortable: true, width: "200%" },
        { text: "Entrega", value: "deliveryType.description", align: "center", sortable: false, width: "30px" },
        { text: "Tipo", value: "exchangeBuy", align: "center", sortable: false, width: "30px" },
        { text: "M.E.", value: "currency.name", align: "center", sortable: false },
        { text: "Valor M.E.", value: "amount", align: "left", sortable: true },
        { text: "Valor Total", value: "totalValue", align: "left", sortable: true },
        { text: "Boleto", value: "bankSlip", align: "center", sortable: false, width: "80px" },
        { text: "Comp. Pagamento", value: "paySlip", align: "center", sortable: false, width: "80px" },
        { text: "Autorização", value: "authDocument", align: "center", sortable: false, width: "80px" },
      ],
      filters: new OperationFiltersModel(),
      listExchangeType: [{ value: true, label: "Compra" }, { value: false, label: "Venda" }],
      currencyList: [],
      bankList: [],
      agentList: [],
      personTypeList: PERSON_TYPE,
      deliveryTypes: DELIVERY_TYPE,
      searchBankId: undefined,
    };
  },
  watch: {},
  mounted() {
    this.getCurrencies();
    this.getBankList();
    this.getAgentList();
    this.filters = new OperationFiltersModel({
      statusId: 2,
      exchangeTypeId: 2,
      startDate: new Date(),
      endDate: new Date(),
    });
    this.getOperations();
  },
  computed: {},
  filters: {
    dateTime(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
    formatCurrency(value, code, precison) {
      code = code || 'USD';

      return numberUtils.formatCurrency(code, value, precison);
    },
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    formatNumber(value) {
      return new Intl.NumberFormat('pt-BR').format(value);
    },
  },
  methods: {
    addDocument(document) {
      let formData = new FormData();
      formData.append("operationId", document.operationId);
      formData.append("idOperationDocumentType", document.operationDocumentType?.id);
      formData.append("document", document.document);

      return apiOperationDocuments
        .add(formData)
        .then((response) => {
          console.log(response.data, 'response');

          return document = {
            ...response.data,
          };
        })
        .catch((error) => {
          console.log(error, 'error');
          this.sendMessage("Erro ao adicionar o anexo!", "error");

          return document = {
            operationDocumentType: document.operationDocumentType,
          };
        });
    },
    downloadDocument(document) {
      return apiOperationDocuments
        .download(document.id);
    },
    goToCustomer(id) {
      mixpanel.track("click", {
        button_name: "go_to_customer",
      });
      this.$router
        .push({
          name: "FormEditCustomer",
          params: {
            id: id,
          },
        })
        .catch(() => { });
    },
    getOperations() {
      this.searchBankId ? this.filters.bankList = [this.searchBankId] : undefined;

      apiOperation
        .find(this.filters)
        .then((response) => {
          this.operations = response.data.map((o) => {
            let operation = new OperationModel(o);
            operation.bankSlip = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.BOLETO_BANCARIO.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.BOLETO_BANCARIO });
            operation.paySlip = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.COMPROVANTE.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.COMPROVANTE });
            operation.authDocument = operation.documents.find(d => d.operationDocumentType.id == OPERATION_DOCUMENT_TYPES.AUTORIZACAO.id) || new OperationDocumentModel({ operationId: operation.id, operationDocumentType: OPERATION_DOCUMENT_TYPES.AUTORIZACAO });

            return operation;
          });
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            this.sendMessage(
              (error.response && error.response.data.mensagem) || error,
              "error"
            );
          }

          this.operations = [];
        });
    },
    getCurrencies() {
      if (this.currencyList[0]) {
        return;
      }

      apiCurrency.findAll()
        .then((response) => {
          this.currencyList = response.data.map(
            (c) => new CurrencyModel(c)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getBankList() {
      apiExchangeBank
        .findAll()
        .then((response) => {
          this.bankList = response.data.map((b) => new ExchangeBankModel(b));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },
    getAgentList() {
      apiCorrespondent
        .findAll()
        .then((response) => {
          this.agentList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped></style>
