import AddressTypeModel from './address-type-model';

export default class CustomerAddressModel {
  constructor(obj) {
    obj = obj || {};

    let addressType = obj.addressType?.map(addressType => new AddressTypeModel(addressType));

    this.id = obj.id;
    this.cep = obj.cep;
    this.country = obj.country;
    this.state = obj.state;
    this.account = obj.account;
    this.city = obj.city;
    this.neighborhood = obj.neighborhood;
    this.street = obj.street;
    this.number = obj.number;
    this.complement = obj.complement;
    this.addressType = addressType?.length ? addressType : [];
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}