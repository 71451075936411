import CommissionPaymentOrderDocumentModel from './commission-payment-order-document-model';

export default class CommissionPaymentOrderModel {
  constructor(obj) {
    obj = obj || {};

    let documents = obj.documents?.map(d => new CommissionPaymentOrderDocumentModel(d));

    this.id = obj.id;
    this.agentId = obj.agentId;
    this.value = obj.value;
    this.requestDate = obj.requestDate;
    this.paymentDate = obj.paymentDate;
    this.documents = documents?.length ? documents : [];
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}
