import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#1E22AA',
        secondary: '#0C2340',
        terciary: '#101820',
        accent: '#82B1FF',
        info: '#03A9F4',
        warning: "#F4C711",
        error: '#FF533D',
        success: '#73BC49',
        backgroundPrimary: '#ECEFF1',
        backgroundSecondary: '#FFFFFF',
        customerExchangeBankWorkflowStatusCadastroEnviado: '#E0E0E0',
        customerExchangeBankWorkflowStatusCadastroEmAndamento: '#FFE082',
        customerExchangeBankWorkflowStatusCadastroFinalizado: '#AED581',
        customerExchangeBankWorkflowStatusCadastroEmAnalise: '#80CBC4',
        customerExchangeBankWorkflowStatusPendenciaDocumental: '#F48FB1',
        customerExchangeBankWorkflowStatusEnviadoAoBanco: '#CE93D8',
        customerExchangeBankWorkflowStatusPldCompliance: '#B0BEC5',
        customerExchangeBankWorkflowStatusCadastroAprovado: '#A5D6A7',
        customerExchangeBankWorkflowStatusCadastroRecusado: '#EF9A9A',
        customerExchangeBankWorkflowStatusAtualizacaoCadastral: '#9FA8DA',
        customerExchangeBankWorkflowStatusEncerrado: '#FFAB91',
        customerExchangeBankWorkflowStatusPendenciaBanco: '#FFE0B2',
        operationWorkflowStatusEmAnalise: '#E0E0E0',
        operationWorkflowStatusComPendencias: '#FFE082',
        operationWorkflowStatusAnalisePendencias: '#F48FB1',
        operationWorkflowStatusEmLiquidacao: '#9FA8DA',
        operationWorkflowStatusLiquidada: '#A5D6A7',
        operationWorkflowStatusCancelada: '#E57373',
        colorSell: '#3F51B5',
        colorBuy: '#7CB342',
      },
      dark: {
        primary: '#1E22AA',
        secondary: '#0C2340',
        terciary: '#101820',
        accent: '#82B1FF',
        info: '#03A9F4',
        warning: "#F4C711",
        error: '#FF533D',
        success: '#73BC49',
        backgroundPrimary: '#121212',
        backgroundSecondary: '#282828',
        customerExchangeBankWorkflowStatusCadastroEnviado: '#E0E0E0',
        customerExchangeBankWorkflowStatusCadastroEmAndamento: '#FFE082',
        customerExchangeBankWorkflowStatusCadastroFinalizado: '#AED581',
        customerExchangeBankWorkflowStatusCadastroEmAnalise: '#80CBC4',
        customerExchangeBankWorkflowStatusPendenciaDocumental: '#F48FB1',
        customerExchangeBankWorkflowStatusEnviadoAoBanco: '#CE93D8',
        customerExchangeBankWorkflowStatusPldCompliance: '#B0BEC5',
        customerExchangeBankWorkflowStatusCadastroAprovado: '#A5D6A7',
        customerExchangeBankWorkflowStatusCadastroRecusado: '#EF9A9A',
        customerExchangeBankWorkflowStatusAtualizacaoCadastral: '#9FA8DA',
        customerExchangeBankWorkflowStatusEncerrado: '#FFAB91',
        customerExchangeBankWorkflowStatusPendenciaBanco: '#FFE0B2',
        operationWorkflowStatusEmAnalise: '#E0E0E0',
        operationWorkflowStatusComPendencias: '#FFE082',
        operationWorkflowStatusAnalisePendencias: '#F48FB1',
        operationWorkflowStatusEmLiquidacao: '#9FA8DA',
        operationWorkflowStatusLiquidada: '#A5D6A7',
        operationWorkflowStatusCancelada: '#E57373',
        colorSell: '#3F51B5',
        colorBuy: '#7CB342',
      }
    }
  }

});