export default class OperationFiltersModel {
  constructor(obj) {
    obj = obj || {};

    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.statusList = obj.statusList;
    this.exchangeTypeId = obj.exchangeTypeId;
    this.exchangeBuy = obj.exchangeBuy;
    this.currencyId = obj.currencyId;
    this.bankList = obj.bankList;
    this.agentId = obj.agentId;
    this.idPersonType = obj.idPersonType;
    this.cpfCnpj = obj.cpfCnpj;
    this.customerId = obj.customerId;
    this.indicatorId = obj.indicatorId;
  }
}