<template>
  <div>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="12"
      >
        <v-spacer class="py-2" />
        <h2>{{ addressName }}</h2>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
        v-if="allowInternational"
      >
        <v-checkbox
          label="CEP internacional"
          v-model="internationalCep"
          dense
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        :md="allowInternational ? 2 : 4"
      >
        <v-text-field
          label="Cep"
          outlined
          v-model="address.cep"
          @change="!internationalCep ? getAddressByCep() : false"
          v-mask="!internationalCep ? '#####-###' : ''"
          dense
          :rules="required ? [validationIsRequiredField] : []"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        :lg="internationalCep ? 3 : 6"
      >
        <v-text-field
          label="Endereço"
          outlined
          v-model="address.street"
          dense
          :rules="required ? [validationIsRequiredField] : []"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-text-field
          label="Número"
          outlined
          v-model="address.number"
          dense
          :rules="required ? [validationIsRequiredField] : []"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
        offset="2"
      >
        <v-text-field
          label="Complemento"
          outlined
          v-model="address.complement"
          dense
          counter="50"
          maxlength="50"
        >
          <template #append-outer>
            <InfoTooltip label="CASA, GALPAO, LOJA, SALA, ETC." />
          </template>
        </v-text-field>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          label="Bairro"
          outlined
          v-model="address.neighborhood"
          dense
          counter="35"
          maxlength="35"
          :rules="required ? [validationIsRequiredField] : []"
        />
      </v-col>
      
      <template v-if="internationalCep">
        <v-col
          class="py-0"
          cols="12"
          md="2"
        >
          <v-text-field
            label="Estado"
            outlined
            v-model="address.state"
            dense
            :rules="required ? [validationIsRequiredField] : []"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          md="3"
        >
          <v-text-field
            label="Cidade"
            outlined
            v-model="address.city"
            dense
            counter="35"
            maxlength="35"
            :rules="required ? [validationIsRequiredField] : []"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          md="3"
        >
          <CountryField
            label="País"
            v-model="address.country"
            :rules="required ? [validationIsRequiredAutocompleteText] : []"
          />
        </v-col>
      </template>
      <template v-else>
        <v-col
          class="py-0"
          cols="12"
          md="2"
        >
          <UFField 
            label="Estado"
            :items="listOfUFs"
            v-model="address.state"
            :rules="required ? [validationIsRequiredAutocompleteText] : []"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          md="3"
        >
          <v-autocomplete
            :items="listOfCities"
            label="Cidade"
            v-model="address.city"
            no-data-text="Selecine uma UF para listar as cidades"
            clearable
            dense
            outlined
            v-disabled-icon-focus
            :rules="required ? [validationIsRequiredAutocompleteText] : []"
          />
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
// Constants
import { REGISTER_TYPE } from "@/constants/general-constants";

// APIs
import viaCepApi from "@/api/general/cep-api";
import apiIBGE from "@/api/general/ibge-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinValidationRules from "@/mixin/mixin-validation-rules";

// Components
import CountryField from "@/components/comum/CountryField.vue";
import UFField from "@/components/comum/UFField.vue";
import InfoTooltip from '@/components/comum/InfoTooltip';

export default {
  name: "AddressComponent",
  mixins: [ mixinMessage, mixinValidationRules ],
  components: { CountryField, UFField, InfoTooltip },
  props: {
    addressProp: {
      type: Object,
    },
    addressName: {
      type: String,
    },
    required: { type: Boolean },
    allowInternational: { type: Boolean, default: true },
  },
  model: {
    prop: "addressProp",
    event: "onChange",
  },
  watch: {
    address: {
      handler() {
        this.$emit("onChange", this.address);
      },
      deep: true,
    },
    "address.state": function (uf) {
      this.getCityByState(uf);
    },
  },
  data() {
    return {
      address: this.addressProp,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      listOfUFs: undefined,
      listOfCities: undefined,
      internationalCep: undefined,
    };
  },

  mounted() {
    this.getUF();

    if (this.address.state) {
      this.getCityByState(this.address.state);
    }
  },
  methods: {
    getUF() {
      apiIBGE.getState().then((resposta) => {
        this.listOfUFs = resposta.data.map((uf) => uf.sigla);
      });
    },
    getAddressByCep() {
      let cep = this.address.cep
        .toString()
        .replaceAll(".", "")
        .replaceAll("-", "");

      viaCepApi
        .getAddressByCEP(cep)
        .then((resposta) => {
          if (resposta.data.erro === undefined) {
            this.address.street = resposta.data.logradouro;
            this.getCityByState(resposta.data.uf);
            this.address.city = resposta.data.localidade;
            this.address.neighborhood = resposta.data.bairro;
            this.address.state = resposta.data.uf;
          } else {
            this.sendMessage("Cep inválido", "error");
          }
        })
        .catch();
    },
    getCityByState(state) {
      apiIBGE.getCity(state).then((resposta) => {
        this.listOfCities = resposta.data.map((c) => c.nome);
      });
    },
  },
};
</script>

<style></style>
