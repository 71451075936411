<template>
  <div class="wrapper">
    <img
      src="@/assets/svg/checked.svg"
      width="80px"
      srcset=""
    >
    <h1>Cadastro enviado com sucesso!</h1>

    <v-btn
      text
      class="btn-secondary"
      @click="goBack()"
    >
      Ir para ao site <i
        class="far pl-2 pr-0 fa-arrow-right"
      />
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'FormCustomerFinally',
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    goBack() {
      const url = process.env.VUE_APP_URL_WEBSITE;
      window.location.href = url;
    },
  }
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  gap: 20px;
}
</style>