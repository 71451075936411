<template>
  <v-card class="box card-cambio">
    <h1>Resumo</h1>
    <v-divider />
    <v-spacer class="py-2" />
    <v-checkbox
      label="Inserir cotação manualmente"
      v-model="operation.manualRate"
      dense
    />
    <OperationPreviewExchange
      v-if="operation.exchangeType.id == operationTypeConstant.EXCHANGE.id"
      v-model="operation"
      :remaining-time-currency-update="remainingTimeCurrencyUpdateFormated"
      :usd-value.sync="usdValue"
    />
    <OperationPreviewRemittance
      v-else
      v-model="operation"
      :remaining-time-currency-update="remainingTimeCurrencyUpdateFormated"
      :usd-value.sync="usdValue"
    />
  </v-card>
</template>

<script>
// Components
import OperationPreviewExchange from "@/components/form-operation/OperationPreviewExchange.vue";
import OperationPreviewRemittance from "@/components/form-operation/OperationPreviewRemittance.vue";

// Constants
import { OPERATION_TYPE } from "@/constants/general-constants";

// Utils
import operationUtils from "@/utils/operation";

// Models
import RateModel from "@/model/rate-model";

// Apis
import rateApi from "@/api/general/rate-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "OperationPreview",
  mixins: [ mixinMessage ],
  components: { OperationPreviewExchange, OperationPreviewRemittance },
  data() {
    return {
      operation: this.operationProp,
      operationTypeConstant: OPERATION_TYPE,
      remainingTimeCurrencyUpdate: 0,
      timer: null,
      interval: null,
      usdValue: 0,
    };
  },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  destroyed() {
    clearTimeout(this.timer);
    clearInterval(this.interval);
  },
  watch: {
    operation() {
      this.$emit("onChange", this.operation);
    },
    "operation.nature"() {
      this.updateValues();
    },
    "operation.country"() {
      this.updateValues();
    },
    "operation.amount"() {
      this.calculateDeliveryCost();
      this.updateValues();
      this.updateReducedLimit();
    },
    "operation.currencyQuotation"() {
      if (this.operation.manualRate) {
        if (this.operation.currency?.code == 'USD') {
          this.usdValue = this.operation.currencyQuotation;
        }

        this.updateValues();
      } else {
        this.updateValuesKeepingCustomerRate();
      }
    },
    "operation.currency"() {
      operationUtils.resetSpreads(this.operation);

      if (!this.operation.manualRate) {
        this.getCurrencyQuotation(true);
      }
    },
    "operation.bank"() {
      if (this.$route.query.contractCost) {
        this.operation.exchangeContractCost = this.$route.query.contractCost;
        this.$route.query.contractCost = undefined;
      } else {
        this.operation.exchangeContractCost = undefined;
      }

      this.operation.useUsdExchangeContract = !!this.operation.bank?.useUsdExchangeContract;

      this.updateValues();
    },
    "operation.spread"() {
      this.updateValues();
    },
    "operation.customerRate"() {
      this.updateValuesKeepingCustomerRate();
    },
    "operation.useUsdExchangeContract"() {
      this.updateBrlExchangeContract();
    },
    "operation.exchangeContractCost"() {
      this.updateBrlExchangeContract();
    },
    "operation.exchangeContractCostBrl"() {
      this.updateValues();
    },
    "operation.deliveryCost"() {
      this.updateValues();
    },
    "usdValue"() {
      this.operation.parity = (+this.operation.currencyQuotation / +this.usdValue).toFixed(operationUtils.BIG_DECIMALS_PLACES);
      this.updateReducedLimit();
      this.updateBrlExchangeContract();
    },
    "operation.manualRate"() {
      if (this.operation.manualRate) {
        clearTimeout(this.timer);
        clearInterval(this.interval);
      } else {
        this.getCurrencyQuotation(true);
      }
    },
  },
  computed: {
    remainingTimeCurrencyUpdateFormated() {
      if (!this.operation.currencyQuotation) {
        return '';
      }

      if (this.remainingTimeCurrencyUpdate < 0) {
        return `Atualizando...`;
      }

      let seconds = Math.floor(this.remainingTimeCurrencyUpdate / 1000);

      return `Atualizando em ${seconds}s`;
    }
  },
  methods: {
    updateValues() {
      if (this.operation.exchangeType.id == this.operationTypeConstant.EXCHANGE.id) {
        this.updateValuesExchange();
      } else {
        this.updateValuesRemittance();
      }
    },

    updateValuesRemittance() {
      operationUtils.setBaseValuesOperationRemittance(this.operation);
      operationUtils.calculateOperationRemittance(this.operation);
    },

    updateValuesExchange() {
      operationUtils.setBaseValuesOperationExchange(this.operation);
      operationUtils.calculateOperationExchange(this.operation);
    },

    updateValuesKeepingCustomerRate() {
      if (this.operation.exchangeType.id == this.operationTypeConstant.EXCHANGE.id) {
        this.updateValuesKeepingCustomerRateExchange();
      } else {
        this.updateValuesKeepingCustomerRateRemittance();
      }
    },

    updateValuesKeepingCustomerRateRemittance() {
      operationUtils.getSpreadFromCustomerRateRemittance(this.operation);
      operationUtils.calculateOperationRemittance(this.operation);
    },

    updateValuesKeepingCustomerRateExchange() {
      operationUtils.getSpreadFromCustomerRateExchange(this.operation);
      operationUtils.calculateOperationExchange(this.operation);
    },

    calculateDeliveryCost() {
      if (this.operation.exchangeType.id == this.operationTypeConstant.EXCHANGE.id) {
        let spread = this.operation.store.exchangeSpreads.find(s => s.currency.id == this.operation.currency.id);

        if (this.operation.deliveryType.id == 2 && this.operation.amount < spread.deliveryCostExemptionAmount) {
          this.operation.deliveryCost = this.operation.store.deliveryCost;
        } else {
          this.operation.deliveryCost = 0;
        }
      }
    },

    getCurrencyQuotation(updateCustomerRate) {
      if (!this.operation.currency?.code) {
        clearTimeout(this.timer);
        this.operation.currencyQuotation = 0;
        clearInterval(this.interval);
        this.interval = null;

        return;
      }

      rateApi.findCommercial({ currencies: `${this.operation.currency.code}` })
        .then((response) => {
          let rate = new RateModel(response.data[0]);
          this.operation.currencyQuotation = rate.ask;

          if (this.$route.query.rate) {
            this.operation.customerRate = this.$route.query.rate;
            this.updateValuesKeepingCustomerRate();
            this.$route.query.rate = undefined;
          } else if (updateCustomerRate) {
            this.updateValues();
          }

          this.getUsdQuotation();
          this.startCurrencyTimeout();
          this.startCurrencyInterval();
        })
        .catch((error) => {
          this.operation.currencyQuotation = 0;
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getUsdQuotation() {
      rateApi.findCommercial({ currencies: `USD` })
        .then((response) => {
          let rate = new RateModel(response.data[0]);
          this.usdValue = rate.ask;
        })
        .catch((error) => {
          this.usdValue = 0;
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    updateReducedLimit() {
      if (!this.operation.bank?.useUsdLimit) {
        this.operation.reducedLimit = this.operation.totalValue;

        return;
      }

      this.operation.reducedLimit = (+this.operation.amount * +this.operation.parity).toFixed(operationUtils.SHORT_DECIMALS_PLACES);
    },
    startCurrencyTimeout() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.getCurrencyQuotation();
      }, operationUtils.REFRESH_RATE_TIME);
    },
    startCurrencyInterval() {
      clearInterval(this.interval);
      this.interval = null;
      this.remainingTimeCurrencyUpdate = operationUtils.REFRESH_RATE_TIME;
      this.interval = setInterval(() => {
        this.remainingTimeCurrencyUpdate -= 1000;
      }, 1000);
    },

    updateBrlExchangeContract() {
      if (!this.operation.useUsdExchangeContract) {
        this.operation.exchangeContractCostBrl = this.operation.exchangeContractCost;

        return;
      }

      this.operation.exchangeContractCostBrl = (+this.operation.exchangeContractCost * +this.usdValue).toFixed(operationUtils.SHORT_DECIMALS_PLACES);
    }

  },
};
</script>

<style></style>
