<template>
  <div>
    <!-- expand on hover is bugged, needs mini-variant to work properly. https://github.com/vuetifyjs/vuetify/issues/14585 -->
    <v-navigation-drawer
      v-model="drawer"
      app
      :expand-on-hover="!isMobile"
      :mini-variant="!isMobile"
      :width="325"
    >
      <MenuStatic />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <img
        v-if="isDark"
        id="brand"
        src="@/assets/brand/logotype-dark.svg"
      >
      <img
        v-else
        id="brand"
        src="@/assets/brand/logotype-light.svg"
      >
      <v-spacer />
      <div class="d-none d-sm-flex">
        <v-btn
          v-if="!isOpenSimulator"
          class="btn-secondary"
          outlined
          @click="openOperationSimulator"
        >
          <i class="far fa-money-bill-wave-alt pr-2" />
          Simular operação
        </v-btn>
        <v-chip
          v-if="isOpenSimulator"
          class="ma-3 text-truncate"
          close
          color="primary"
          text-color="white"
          @click:close="closeOperationSimulator"
        >
          <span class="pr-2">
            Simulando operação
          </span>
        </v-chip>
      </div>
      <div class="d-flex d-sm-none">
        <v-btn
          v-if="!isOpenSimulator"
          icon
          color="primary"
          @click="openOperationSimulator"
        >
          <i class="far fa-money-bill-wave-alt pr-2" />
        </v-btn>
        <v-chip
          v-if="isOpenSimulator"
          class="ma-3 text-truncate "
          close
          color="primary"
          text-color="white"
          @click:close="closeOperationSimulator"
        >
          <i class="far fa-money-bill-wave-alt pr-2" />
        </v-chip>
      </div>
      <v-menu offset-y>
        <template #activator="{ on: menu, attrs }">
          <v-btn
            color="primary"
            icon
            v-bind="attrs"
            v-on="{ ...menu }"
          >
            <i class="far fa-user-cog" />
          </v-btn>
        </template>
        <v-list width="180px">
          <v-list-item-group>
            <v-list-item @click="changeTheme">
              <i
                v-if="$vuetify.theme.dark"
                class="far fa-sun"
              />
              <i
                v-else
                class="far fa-moon"
              />
              Alterar tema
            </v-list-item>
            <v-list-item @click="goToConfigurations">
              <i class="far fa-wrench" />
              Configurações
            </v-list-item>
            <v-list-item @click="goToEmailLogs">
              <i class="far fa-envelope" />
              E-mails Enviados
            </v-list-item>
            <v-list-item @click="changePassword()">
              <i class="far fa-lock-alt" />
              Alterar Senha
            </v-list-item>
            <v-list-item @click="logout()">
              <i class="far fa-sign-out" />
              Sair
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-dialog
      width="300px"
      v-model="changePasswordWindow"
    >
      <v-card>
        <v-card-title>
          <h3>Alterar Senha</h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-5">
          <v-text-field
            label="Nova Senha"
            required
            :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            autofocus
            v-model="user.newPassword"
          />
          <v-text-field
            label="Confirme sua senha"
            required
            :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
            v-model="user.confirmNewPassword"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="updatePassword()"
            class="btn-primary"
            text
          >
            Salvar
          </v-btn>
          <v-btn
            @click="close()"
            class="btn-tertiary"
            text
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

// Api
import apiUser from "@/api/user/user-api";
import configurationApi from "../../api/generic/configuration-api";

// Models
import UserModel from "@/model/user-model";
import ConfigurationModel from "@/model/generic/configuration-model";

// Components
import MenuStatic from "@/components/menu/MenuStatic.vue";

// Utils
import utilsStorage from "@/utils/storage";

// Mixin
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";

// Tracking
import mixpanel from "mixpanel-browser";

import { OPTIONS_STORE_CONFIGURATIONS } from "@/vuex/module/configurations";

export default {
  name: "Menu",
  mixins: [mixinMessage, mixinGeral],
  components: {
    MenuStatic,
  },
  data() {
    return {
      drawer: true,
      routes: [],
      changePasswordWindow: false,
      value: "password",
      user: new UserModel(),
      email: "",
    };
  },
  mounted() {
    let user = utilsStorage.getUserOnStorage();

    if (!user) return;

    this.user = new UserModel(user);

    this.startPendo();
    this.getAllConfigurationsForStore();
  },
  computed: {
    isMobile() {
      return window.innerWidth <= '960';
    },
  },
  props: {
    isOpenSimulator: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'isOpenSimulator',
    event: 'onChange'
  },

  methods: {
    openOperationSimulator() {
      this.$emit('onChange', true);
    },
    closeOperationSimulator() {
      this.$emit('onChange', false);
    },
    goToConfigurations() {
      this.$router.push({ name: "Configuration" })
        .catch(() => { });
    },

    goToEmailLogs() {
      this.$router.push({ name: "EmailLogsControl" })
        .catch(() => { });
    },

    getAllConfigurationsForStore() {
      configurationApi.findAll()
        .then(response => {
          let configurations = response.data.map(c => new ConfigurationModel(c));
          this.updateAllConfigurationsStoreBasedAPI(configurations);
        })
        .catch(() => { });
    },
    updateAllConfigurationsStoreBasedAPI(configurations) {
      this.$store.dispatch(OPTIONS_STORE_CONFIGURATIONS.ACTIONS.UPDATE_CONFIGURATION_MODEL, configurations);
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      utilsStorage.setThemeSettings(this.$vuetify.theme.dark);
    },
    changePassword() {
      this.changePasswordWindow = true;
      mixpanel.track("click", {
        button_name: "btn_change_password",
      });
    },
    exit() {
      mixpanel.track("click", {
        button_name: "btn_logout",
      });
      this.logout();
    },

    logout() {
      apiUser
        .logout()
        .then(() => {
          utilsStorage.removeAuth();
          this.$eventBus.$emit('token-changed');
          this.$router.push({ name: "Login" });
        })
        .catch((error) =>
          this.sendMessage(error.response.data.mensagem, "error")
        );
    },
    startPendo() {
      if (!window.pendo) return;

      window.pendo.initialize({
        visitor: {
          id: this.user.id,
          email: this.user.email,
          full_name: this.user.name,
          role: this.user.profileId,
        },
        account: {
          id: this.user.id,
        },
      });
    },
    updatePassword() {
      if (this.user.newPassword === this.user.confirmNewPassword) {
        apiUser
          .changePassword(this.user.newPassword)
          .then(() => {
            this.sendMessage("Senha alterada com sucesso", "success");
            this.changePasswordWindow = false;
            this.cleanFields();
          })
          .catch(() => {
            this.sendMessage("Não foi possível alterar a senha", "error");
          });
      } else {
        this.sendMessage("As senhas devem ser iguais", "error");
      }
    },

    close() {
      this.cleanFields();
      this.changePasswordWindow = false;
    },

    cleanFields() {
      this.user = new UserModel();
    },
  },
};
</script>

<style lang="scss" scoped>
#brand {
  max-height: 35px !important;
}
</style>
