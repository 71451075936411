
function validateCPF(cpf) {
    if(!cpf) return false;

    const notDig = i => !['.', '-', ' '].includes(i);
    const prepare = cpf => cpf.trim().split('').filter(notDig).map(Number);
    const is11Len = cpf => cpf.length === 11;
    const notAllEquals = cpf => !cpf.every(i => cpf[0] === i);
    const onlyNum = cpf => cpf.every(i => !isNaN(i));

    const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx);
    const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0);
    const resto11 = somaDig => 11 - (somaDig % 11);
    const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11;

    const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)));
    const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos];

    const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)];
    const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r);

    return checkAll(prepare(cpf));
}

function validateCNPJ(cnpj) {
    if(!cnpj) return false;

    cnpj = cnpj.replace(/[^\d]+/g,'');

    if(cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Remove CNPJs invalid or known
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0,size);
    let digits = cnpj.substring(size);
    let plus = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
    plus += numbers.charAt(size - i) * pos--;

    if (pos < 2)
            pos = 9;
    }
    let result = plus % 11 < 2 ? 0 : 11 - plus % 11;

    if (result != digits.charAt(0))
        return false;

    size = size + 1;
    numbers = cnpj.substring(0,size);
    plus = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
    plus += numbers.charAt(size - i) * pos--;

    if (pos < 2)
            pos = 9;
    }
    result = plus % 11 < 2 ? 0 : 11 - plus % 11;

    if (result != digits.charAt(1))
        return false;

    return true;

}

export default {
    validateCNPJ,
    validateCPF
};
