<template>
  <div class="ma-5">
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <h1>Autorização da Ficha</h1>
        <v-divider />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <FormCustomer is-in-analysis />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          class="btn-tertiary float-right"
          text
          @click="cancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="btn-warning mx-2 float-right"
          @click="customerComplentaryInfoModal = true"
          text
        >
          <i class="fas fa-comment-exclamation" />
          Registrar Pendência
        </v-btn>
      </v-col>
    </v-row>

    <!-- Modal de Informações Adicionais do Template -->
    <v-dialog
      persistent
      scrollable
      eager
      min-width="20vw"
      max-width="80vh"
      v-model="customerComplentaryInfoModal"
    >
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Informação complementar</h1>
        </v-card-title>
        <v-divider class="" />
        <v-card-text class="pt-8">
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              md="12"
            >
              <v-textarea
                auto-grow
                outlined
                rows="1"
                v-model="customerComplentaryInfo"
                label="Informações complementares"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primary mr-2"
              @click="registerPendency()"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-tertiary"
              @click="customerComplentaryInfoModal = false"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Components
import FormCustomer from '@/views/customers/FormCustomer';

// Constants
import { SEND_EMAIL_TRIGGER_CODES } from "../../../../common/constants/generic/triggers";
import { CUSTOMER_WORKFLOW } from "../../../../common/workflows/customersWorkflow";
import { CUSTOMER_EXCHANGE_BANK_WORKFLOW } from "../../../../common/workflows/customerExchangeBankWorkflow";

// Apis
import apiCustomers from "@/api/customer/customer-api";
import apiCustomerExchangeBanks from "@/api/customer/customer-exchange-banks-api";
import apiTrigger from "@/api/generic/trigger-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";
import mixinNestedFormValidation from "@/mixin/mixin-nested-form-validation";

// Models
import TriggerModel from "@/model/generic/trigger-model";


// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "FormAuthorizationCustomer",
  mixins: [mixinMessage, mixinGeral, mixinNestedFormValidation],
  components: {
    FormCustomer,
  },
  data() {
    return {
      customerId: undefined,
      bankId: undefined,
      customerComplentaryInfo: '',
      customerComplentaryInfoModal: false,
    };
  },
  mounted() {
    this.customerId = this.$route.params.id;
    this.bankId = this.$route.query.bankId;
  },
  computed: {

  },
  methods: {
    cancel() {
      mixpanel.track("click", {
        button_name: "cancel_authorization_form_customer",
      });

      this.$router.push({
        name: "CustomersAuthorizationControl",
      });
    },
    registerPendency() {
      mixpanel.track("click", {
        button_name: "register_pendency_form_customer",
      });

      this.registerActionRegisterFormPendency();

      if (this.bankId) {
        this.updateExchangeBankStatus(CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_DOCUMENTAL);
      } else {
        this.updateCustomerStatus(CUSTOMER_WORKFLOW.PENDECIA_DOCUMENTAL);
      }
    },
    updateCustomerStatus(dto) {
      apiCustomers
        .updateStatus(this.customerId, dto)
        .then(() => {
          this.sendMessage("Status atualizado com sucesso!", "success");

          this.$router.push({
            name: "CustomersAuthorizationControl",
          });
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    updateExchangeBankStatus(dto) {
      apiCustomerExchangeBanks
        .updateStatus(this.customerId, this.bankId, dto)
        .then(() => {
          this.sendMessage("Status atualizado com sucesso!", "success");

          this.$router.push({
            name: "CustomersAuthorizationControl",
          });
        })
        .catch((error) => {
          if (dto.id == CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_DOCUMENTAL.id) {
            this.updateExchangeBankStatus(CUSTOMER_EXCHANGE_BANK_WORKFLOW.PENDECIA_BANCO);

            return;
          }

          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    registerActionRegisterFormPendency() {
      let trigger = new TriggerModel({
        code: SEND_EMAIL_TRIGGER_CODES.CLIENTE_PENDENCIA_CADASTRAL,
        idCustomer: this.customerId,
        customerComplementaryInfo: this.customerComplentaryInfo,
      });

      apiTrigger
        .trigger(trigger)
        .then(() => {
          this.sendMessage("Email de pendência cadastral enviado com sucesso!", "success");
          this.customerComplentaryInfo = '';
          this.customerComplentaryInfoModal = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>
