<template>
  <v-app>
    <Menu
      v-model="isOpenSimulator"
      v-if="this.$router.currentRoute.name != 'Login' &&
        this.$router.currentRoute.name != 'FormEditCustomerExternalAcess' &&
        this.$router.currentRoute.name != 'FormEditCorrespondentExternalAcess' &&
        this.$router.currentRoute.name != 'FileView' &&
        this.$router.currentRoute.name != 'FormCustomerFinally' &&
        this.$router.currentRoute.name != 'CustomerDocumentsExternalAccess' &&
        this.$router.currentRoute.name != 'OperationDocumentsExternalAccess'
      "
    />
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="type"
      elevation="0"
      right
    >
      {{ message }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <OperationSimulator
      v-model="isOpenSimulator"
      v-if="this.$router.currentRoute.meta.requireAuth && isOpenSimulator && this.$router.currentRoute.name != 'FileView'"
    />
    <v-main>
      <router-view />
      <div class="footer">
        <div class="by">&copy; {{ new Date().getFullYear() }} - Powered by DeepRocket Tecnologia LTDA</div>
        <div by="version">{{ version }}</div>
      </div>
    </v-main>
  </v-app>
</template>

<script>

// Components
import Menu from "@/components/menu/Menu";
import OperationSimulator from '@/components/comum/OperationSimulator';

// Mixins
import mixinAuthorization from "@/mixin/mixin-authorization";

// Storage
import utilsStorage from "@/utils/storage";

export default {
  name: "App",
  mixins: [mixinAuthorization],
  components: {
    Menu,
    OperationSimulator,
  },

  data() {
    return {
      isOpenSimulator: false,
      version: process.env.VUE_APP_APP_VERSION,
      message: '',
      snackbar: false,
      timeout: 5000,
      type: '',
    };
  },
  mounted() {
    this.verifyTheme();
    this.$eventBus.$on('snackbar-message', this.showSnackbar);
  },
  computed: {},
  methods: {
    verifyTheme() {
      this.$vuetify.theme.dark = utilsStorage.getThemeSettings();
    },
    showSnackbar(snackbar) {
      this.type = snackbar.type;
      this.message = snackbar.message;
      this.snackbar = true;
      this.timeout = snackbar.timeout || 5000;
    }
  },
};
</script>
<style lang="scss" scoped>
.versao {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0px 15px 15px 5px;
}


.footer {
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
</style>
