export default class ConfigurationMenuModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.session = obj.session;
    this.group = obj.group;
    this.name = obj.name;
    this.title = obj.title;
    this.page = obj.page;
  }
}