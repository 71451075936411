<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1>Informações de contato</h1>
        <v-divider class="py-3" />
        <PFCustomerContact
          v-if="isPF"
          v-model="customer"
        />
        <PJCustomerContact
          v-else
          v-model="customer"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PFCustomerContact from "./PF/contact/PFCustomerContact.vue";
import PJCustomerContact from "./PJ/contact/PJCustomerContact.vue";

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";

export default {
  name: "CustomerContact",
  components: { PFCustomerContact, PJCustomerContact },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {
    isPF() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.LEGAL_PERSON.id
        ? false
        : true;
    },
  },
  data() {
    return {
      customer: this.customerProp,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
    };
  },
};
</script>

<style>
</style>