const mixinAutoCompleteFilters = {
  data() {
    return {

    };
  },
  methods: {
    caseInsensitiveAccentsInsensitiveIgnoreDotsDashes(item, query, itemText) {
      if (!itemText || !query) {
        return;
      }

      let normalizedValue = itemText.toString().normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();
      let normalizedSearch = query.toString().normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase();
      
      normalizedValue = normalizedValue.replace(/-|\/|\./g, '');
      normalizedSearch = normalizedSearch.replace(/-|\/|\./g, '');

      return normalizedValue.indexOf(normalizedSearch) != -1;
    },
  }
};

export default mixinAutoCompleteFilters;