import DeliveryTimeModel from './delivery-time-model';
import DeliveryTypeModel from './delivery-type-model';
import SpreadModel from './spread-model';
import WithdrawalDateModel from './withdrawal-date-model';

export default class ExchangeStoreModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.cnpj = obj.cnpj;
    this.name = obj.name;
    this.landline = obj.landline;
    this.cellphone = obj.cellphone;
    this.mail = obj.mail;
    this.bank = parseInt(obj.bank) || undefined;
    this.agency = obj.agency;
    this.account = obj.account;
    this.digit = obj.digit;
    this.cep = obj.cep;
    this.country = obj.country;
    this.state = obj.state;
    this.city = obj.city;
    this.neighborhood = obj.neighborhood;
    this.street = obj.street;
    this.number = obj.number;
    this.complement = obj.complement;
    this.deliveryType = new DeliveryTypeModel(obj.deliveryType);
    this.deliveryTime = new DeliveryTimeModel(obj.deliveryTime);
    this.deliveryCost = obj.deliveryCost;
    this.deliverySunday = obj.deliverySunday;
    this.deliveryMonday = obj.deliveryMonday;
    this.deliveryTuesday = obj.deliveryTuesday;
    this.deliveryWednesday = obj.deliveryWednesday;
    this.deliveryThursday = obj.deliveryThursday;
    this.deliveryFriday = obj.deliveryFriday;
    this.deliverySaturday = obj.deliverySaturday;
    this.withdrawalDate = new WithdrawalDateModel(obj.withdrawalDate);
    this.withdrawalSunday = obj.withdrawalSunday;
    this.withdrawalMonday = obj.withdrawalMonday;
    this.withdrawalTuesday = obj.withdrawalTuesday;
    this.withdrawalWednesday = obj.withdrawalWednesday;
    this.withdrawalThursday = obj.withdrawalThursday;
    this.withdrawalFriday = obj.withdrawalFriday;
    this.withdrawalSaturday = obj.withdrawalSaturday;
    this.businessHours = obj.businessHours;
    this.observation = obj.observation;
    this.exchangeSpreads = obj.exchangeSpreads?.map(s => new SpreadModel(s)) || [];
    this.fantasyName = obj.fantasyName;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}