import OperationDocumentModel from "../operation-document-model";
import CustomerOperationalDocumentModel from "../customer-operational-document-model";

export default class OperationExternalAccessDocumentsModel {
  constructor(obj) {
    obj = obj || {};

    this.documents = obj.documents?.map(d => new OperationDocumentModel(d));
    this.customerOperationalDocuments = obj.customerOperationalDocuments?.map(d => new CustomerOperationalDocumentModel(d));
  }
}
