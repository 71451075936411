<template>
  <v-row>
    <v-col
      cols="12"
      md=""
    >
      <div class="box">
        <h1>{{ isLegalPerson ? 'Documentos da Empresa' : 'Documentos' }}</h1>
        <v-row class="pt-3">
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <v-divider class="py-3" />
          </v-col>
        </v-row>
        <PJCustomerDocuments
          v-if="isLegalPerson"
          v-model="customer"
        />
        <PFCustomerDocuments
          v-else
          :is-exchange="isExchange"
          :is-basic-remitance="isBasicRemitance"
          :is-complete-remittance="isCompleteRemittance"
          v-model="customer"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PFCustomerDocuments from "./PF/document/PFCustomerDocuments.vue";
import PJCustomerDocuments from "./PJ/document/PJCustomerDocuments.vue";

// APIs
import apiCustomersDocuments from "@/api/customer/customer-documents-api";

export default {
  name: "CustomerDocuments",
  components: { PFCustomerDocuments, PJCustomerDocuments },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
    isExchange: {
      type: Boolean,
    },
    isBasicRemitance: {
      type: Boolean,
    },
    isCompleteRemittance: {
      type: Boolean,
    },
    isLegalPerson: {
      type: Boolean,
    },
  },
  data() {
    return {
      customer: this.customerProp,
      apiDocument: apiCustomersDocuments,
    };
  },
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style>

</style>