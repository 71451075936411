<template>
  <div class="">
    <div class="wrapper">
      <i class="far fa-exclamation-triangle fa-5x" />
      <h1>A página que você está tentando <br>acessar não existe ou não foi encontrada</h1>
      <h3>Verifique o endereço digitado ou tente novamente.</h3>
      <v-btn
        text
        @click="$router.go(-1)"
        class="btn-primary"
      >
        Voltar
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  components: {},
  data() {
    return {};
  },
  mounted() { },
  methods: {},
};
</script>

<style lang="scss" scoped>
h1 {
  color: var(--v-primary-base);
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: 80vh;
  padding: 20px;
  text-align: center;
}

.wrapper h1 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0;
}

.wrapper h3 {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0;
}

.wrapper i {
  color: var(--v-primary-base);
}
</style>