
export default class CommissionStatementModel {
  constructor(obj) {
    obj = obj || {};

    this.date = obj.date;
    this.operationId = obj.operationId;
    this.agentValue = obj.agentValue;
    this.indicatorValue = obj.indicatorValue;
    this.withdrawalValue = obj.withdrawalValue;
    this.nf = obj.nf;
    this.proof = obj.proof;
  }
}
