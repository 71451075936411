export default class DashboardOperationAdministrativeModel {
  constructor(obj) {
    obj = obj || {};

    this.operationSubTotal = obj.operationSubTotal;
    this.bankComissionValue = obj.bankComissionValue;
    this.cambioHojeComissionValue = obj.cambioHojeComissionValue;
    this.agentValue = obj.agentValue;
    this.indicatorValue = obj.indicatorValue;
    this.grossProfit = obj.grossProfit;
  }
}
