
import moment from 'moment';

function get() {
  return [
    {
      name: "Datas",
      children: [
        {
          name: "Dia atual",
          attribute: "Dia_Atual",
          title: "Dia atual",
          inline: true,
          content: () => {
            return moment().format('DD');
          }
        },
        {
          name: "Mês atual",
          attribute: "Mes_Atual",
          title: "Mês atual",
          inline: true,
          content: () => {
            return moment().format('MM');
          }
        },
        {
          name: "Ano atual",
          attribute: "Ano_Atual",
          title: "Ano atual",
          inline: true,
          content: () => {
            return moment().format('YYYY');
          }
        },
        {
          name: "Data atual",
          attribute: "Data_Atual",
          title: "Data atual",
          inline: true,
          content: () => {
            return moment().format('DD/MM/YYYY');
          }
        },
        {
          name: "Hora atual com segundos",
          attribute: "Hora_Atual_Com_Segundos",
          title: "Hora atual",
          inline: true,
          content: () => {
            return moment().format('HH:mm:ss');
          }
        },
        {
          name: "Hora atual sem segundos",
          attribute: "Hora_Atual_Sem_Segundos",
          title: "Hora atual",
          inline: true,
          content: () => {
            return moment().format('HH:mm');
          }
        }
      ]
    }
  ]
}

export default { get }