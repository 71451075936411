import moment from 'moment';

const FILTER_TYPE = {
  TODAY: {
    START_DATE: moment().startOf('day').format(),
    END_DATE: moment().endOf('day').format()
  },
  THIS_WEEK: {
    START_DATE: moment().startOf('week').format(),
    END_DATE: moment().endOf('week').format()
  },
  THIS_MONTH: {
    START_DATE: moment().startOf('month').format(),
    END_DATE: moment().endOf('day').format()
  },
  LAST_12_MONTHS: {
    START_DATE: moment().subtract(12, 'months').startOf('month').format(),
    END_DATE: moment().endOf('day').format()
  }
};

const FILTER_ITEMS = [
  { label: 'Hoje', value: FILTER_TYPE.TODAY },
  { label: 'Esta semana', value: FILTER_TYPE.THIS_WEEK },
  { label: 'Este mês', value: FILTER_TYPE.THIS_MONTH },
  { label: 'Últimos 12 meses', value: FILTER_TYPE.LAST_12_MONTHS }
];

const CHART_COLORS = [
  '#E91E63',
  '#03A9F4',
  '#9C27B0',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#673AB7',
  '#009688',
  '#FDD835',
  '#9E9E9E',
  '#3F51B5',
  '#607D8B'
];

const ICON_TYPE = {
  PERCENT: {
    ICON: 'fas fa-percent',
    COLOR: '#FFC107'
  },
  CURRENCY: {
    ICON: 'fas fa-dollar-sign',
    COLOR: '#4CAF50'
  },
  INTEGER: {
    ICON: 'fas fa-hashtag',
    COLOR: '#2196f3'
  }
};

export { FILTER_TYPE, FILTER_ITEMS, CHART_COLORS, ICON_TYPE };
