import api from '../api';

function login(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.post('/auth/login', { email: user.email, password: user.password })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function logout() {
  return new Promise((resolve, reject) => {
    return api.delete('/auth/logout')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findAll() {
  return new Promise((resolve, reject) => {
    return api.get('/users')
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function findId(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.get(`/users/${user.id}`, user)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function add(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.post('/users', user)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function update(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.put(`/users/${user.id}`, user)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function inactive(user) {
  user = user || {};

  return new Promise((resolve, reject) => {
    return api.patch(`/users/${user.id}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function changePassword(password) {
  return new Promise((resolve, reject) => {
    return api.put(`/auth/change-password`, {
      password
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    return api.post(`/auth/forgot-password`, {
      email
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function validateUserByCode(email, code) {
  return new Promise((resolve, reject) => {
    return api.post(`/auth/code-login`, {
      email, code
    })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  login,
  logout,
  findAll,
  findId,
  add,
  update,
  inactive,
  changePassword,
  forgotPassword,
  validateUserByCode
};